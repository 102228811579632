import React, { useState, useEffect, useRef } from 'react'
import LeftNav from "../LeftNav"
import './index.css'
import { Button, Icon, IconButton, TextField, Tooltip } from '@material-ui/core';

import { createBatch, getBatchDetails, getBatchStudents, updateBatchStudentList, deleteBatch, updateUser, uploadBatch, addParticipant, updateBatch } from "../../application/redux_actions"
// import { getBatchDetails, getBatchStudents, updateUser, addParticipant, updateBatch } from "../../application/redux_actions"
import DropDown from './DropDown';
import Table from './Table';
import UploadDialog from './UploadDialog';
import { useTranslation } from 'react-i18next';
import {
    Edit as EditIcon,
    PersonAdd as PersonAddIcon,
    Info as InfoIcon,
    Save as SaveIcon,
    Close as CloseIcon
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import "./index.scss"
import AddCollaborator from './AddCollaborator';

import CustomToolTip from './ToolTip';

export default ({ app, auth }) => {
    const [batches, setBatches] = useState({});
    const [edit, setEdit] = useState(false);
    const [rename, setRename] = useState(false);
    const [loading, setLoading] = useState(false)
    // const addButtonRef = useRef(null);
    const [batch, setBatch] = useState(null);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [addCollaboratorDialogOpen, setAddCollaboratorDialogOpen] = useState(false);
    const [newBatch, setNewBatch] = useState({
        show: false,
        name: ''
    });
    const [editData, setEditData] = useState({});

    const [dropDownInput, setDropDownInput] = useState('');

    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(false)

    useEffect(async () => {
        setLoading(true);
        await getBatchDetails({ user_token: auth.user_token, user_id: auth.user_uid }).then(async (res) => {
            if (res)
                for (const batch of res)
                    await getBatchStudents({ user_token: auth.user_token, batchId: batch._id })
        })
        setLoading(false);
    }, [])

    useEffect(() => {
        setBatches(app.batchStudentsDetails)
    }, [app.batchStudentsDetails])

    useEffect(() => {
        refresh && window.location.reload(false)
    }, [refresh])

    const changeBatchName = (key, newBatchName) => (event) => {
        if (newBatchName)
            setNewBatch({ ...newBatch, name: event.target.value })

        else
            setBatches((oldBatch) => {
                return { ...oldBatch, [key]: { ...oldBatch[key], batchName: event.target.value } }
            })
    }

    const studentsInList = batches[batch]?.students?.length > 0

    const buttonStyle = {
        border: '3px solid #FFBC36',
        borderRadius: '18px',
        padding: "0.4em 2em",
        display: 'flex',
        alignSelf: 'baseline',
        width: studentsInList ? null : "fit-content",
        marginLeft: "auto",
        marginRight: "auto"
    }

    const divStyle = {
        textAlign: "center",
        marginLeft: studentsInList ? "auto" : null,
        display: "grid",
        gridTemplateColumns: studentsInList ? "1fr 1fr" : null,
        gridColumnGap: "10px",
        gridRowGap: "10px"
    }

    // useEffect(() => {
    //     Object.values(batches)[0] && Object.values(batches)[0].batchId && setBatch(Object.values(batches)[0]?.batchName)
    // }, [batches, loading])


    return <>
        <div className={"dashboard-cntr"}>
            <LeftNav />
            <div className='d-flex w-100 h-100'>
                <div className='w-100 h-100 tct-scrollable-darkgray overFlowYAuto' style={{ padding: '1.5rem', backgroundColor: '#F7F7F7' }}>
                    <span className="ap-program-count" style={{ color: "black", margin: "0" }}>
                        {t('YOUR_BACTHES')}
                    </span>
                    <div style={{ display: "flex", flexDirection: "column", width: 'fit-content', width: '100%', marginTop: "10px" }}>
                        {/* <span style={{ fontSize: '0.8em' }}>Select Batch</span> */}
                        <div className='d-flex mb-10px  justify-content-space-between align-items-flex-start'>

                            <div className='w-60 d-flex'>

                                {rename || newBatch.show ? <TextField placeholder='Enter Batch name' value={newBatch.show ? newBatch.name : batches?.[batch]?.batchName} onChange={newBatch.show ? changeBatchName(batch, true) : changeBatchName(batch, false)} /> :
                                    <Autocomplete
                                        id="Autocomplete-demo"
                                        value={batch}
                                        options={Object.keys(batches).map(item => item)}
                                        inputValue={dropDownInput}
                                        onChange={(event, newValue) => {
                                            setBatch(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setDropDownInput(newInputValue);
                                        }}
                                        getOptionLabel={(option) => option}

                                        style={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Select Batch" variant="outlined" />}
                                    />
                                    // <DropDown
                                    //     options={Object.values(batches).map((batch) => ({ label: batch.batchName, value: batch.batchName }))}
                                    //     value={batch || ''}
                                    //     setBatch={setBatch}
                                    //     // label='Select Batch'
                                    // />
                                }
                                {!newBatch.show ? <Button style={{ ...buttonStyle, backgroundColor: 'rgb(255, 186, 54)', margin: '0 15px' }} onClick={() => setNewBatch({ ...newBatch, show: true })}>Create new batch</Button> : null}
                            </div>

                            {rename && !newBatch.show ? <div className='d-flex'>
                                <Button className='batch-rename-button' onClick={() => {
                                    updateBatch(auth.user_token, { batchId: batches?.[batch]?.batchId }, { batchName: batches?.[batch]?.batchName }).then(res => {
                                        if (res?.code)
                                            alert('Rename Failed.')
                                        else {
                                            alert('Renamed Successfully');
                                            setRename(false);
                                        }

                                    });

                                }}> <SaveIcon />Save Name</Button>
                                <Button className='batch-rename-button' onClick={() => setRename(false)}><CloseIcon />Cancel</Button>
                            </div> : !newBatch.show && <div className='d-flex'>
                                <Button className='batch-rename-button' onClick={() => setRename(true)}> <EditIcon /> Rename Batch</Button>
                                <Button className='batch-rename-button' onClick={() => setAddCollaboratorDialogOpen(true)}> <PersonAddIcon /> Add Master Trainer</Button>
                            </div>}
                        </div>
                        {
                            newBatch.show ? <div className="batch-card w-auto">
                                <Button style={{ ...buttonStyle, borderColor: 'rgb(255, 186, 54)', backgroundColor: studentsInList ? null : 'rgb(255, 186, 54)' }}
                                    onClick={() => {
                                        if (batches?.[batch]?.batchName && batches?.[batch]?.batchName != "")
                                            setUploadDialogOpen(true)
                                        else alert("Please enter batch name")
                                    }}
                                >UPLOAD A LIST</Button>
                                <Button style={{ ...buttonStyle, marginTop: '15px' }} onClick={() => setNewBatch({ ...newBatch, show: false })} >CANCEL</Button>
                            </div > :
                                <>
                                    <div>
                                        {batches[batch]?.coach && batches[batch]?.coach?.length > 1 && <div className='d-flex' style={{ fontSize: '0.8rem' }}>
                                            <div className='mr-0-5rem'>Collaborated with: </div>
                                            {
                                                batches[batch]?.coach.map(coach => {
                                                    if (coach?._id !== auth.user_uid)
                                                        return <div key={coach?._id} className='font-weight-bold'>{coach?.fullname}</div>
                                                    return null;
                                                })
                                            }
                                        </div>}
                                    </div>
                                    {!loading && <div className='batch-card'>
                                        <span className='key'>Zone</span>
                                        <span className='value'>{batches[batch]?.students?.[0].Zone}</span>
                                        <span className='key'>Division</span>
                                        <span className='value'>{batches[batch]?.students?.[0].Division}</span>
                                        <span className='key'>Registered Participants</span>
                                        <span className='value'>{batches[batch]?.students?.filter((stu) => stu.Status).length}/{batches[batch]?.students?.length}</span>
                                    </div>}
                                    {/* <div className='batch-card'>
                            <div className='fw-bold mb-10px'>Assessments Status</div>
                            <div className='assessment-status'>
                                <div>
                                 {[1, 2, 3, 4].map((ass) => (<div>
+                                    <div>Assessment {ass} (Code - 3579)</div>
                                     <div className='completed'>
                                         <div>
                                             <span> 21 </span>
                                             <span>/23 </span>
                                         </div>
                                         <div>Completed</div>
                                     </div>
                                    </div>
                        </div> */}
                                    <div className="batch-card w-auto">
                                        {loading ? <div>Loading...</div> : <div style={{ display: studentsInList ? "flex" : "block" }}>
                                            <div className='fw-bold flex-center'>
                                                <div className='mr-0-5rfem'>Batch Participants</div>
                                                <Tooltip title={<CustomToolTip />} classes={{ tooltip: 'batch-tooltip batch-tooltip-size_1' }} placement="bottom-start">
                                                    <InfoIcon aria-label="delete" style={{ fill: "#898989" }} />
                                                </Tooltip>
                                            </div>
                                            <div style={divStyle}>
                                                <Button style={{ ...buttonStyle, borderColor: 'rgb(255, 186, 54)', backgroundColor: studentsInList ? null : 'rgb(255, 186, 54)' }}
                                                    onClick={() => { setUploadDialogOpen(true) }}
                                                >UPLOAD A LIST</Button>
                                                <Button style={{ ...buttonStyle, borderColor: 'rgb(255, 186, 54)', backgroundColor: studentsInList ? 'rgb(255, 186, 54)' : null }}
                                                    onClick={() => {

                                                        let students = [...batches[batch].students];
                                                        students.push({
                                                            "S.No.": students.length + 1,
                                                            userId: "",
                                                            "Status": false,
                                                            "Participant Name": "",
                                                            "Contact number": "",
                                                            "Division": "",
                                                            "Zone": "",
                                                            "Designation": "",
                                                            "Group": ""
                                                        })
                                                        setBatches({ ...batches, [batch]: { ...batches[batch], students: students } })
                                                    }}
                                                >ADD PARTICIPANT</Button>
                                            </div>
                                        </div>}
                                        {!loading && batches[batch]?.students?.length > 0 ? <div style={{ overflowX: "auto", marginTop: "1%" }}>
                                            <Table data={batches[batch]?.students || []} showAction={true}
                                                setData={(index, action, data) => {
                                                    // if (action == "delete")
                                                    //     setBatches({ ...batches, [batch]: { ...batches[batch], students: batches[batch]?.students.filter((student, location) => location !== index) } })
                                                    // else                   \
                                                    console.log('data', data);
                                                    if (action == "save") {

                                                        for (const b of Object.keys(data)) {
                                                            console.log('b', b);
                                                            if (batches[batch]) {

                                                                batches[batch].students[b] = {
                                                                    ...batches[batch]?.students[b],
                                                                    ...data[b]
                                                                }
                                                                let str = '';

                                                                console.log(batches[batch].students[b], data[b])
                                                                if (batches[batch].students[b].userId)
                                                                    updateUser(auth.user_token, batches[batch].students[b]).then((res) => {
                                                                        if (res?.code) {
                                                                            if ((res.message).includes('phone'))
                                                                                alert("Duplicate phone number");
                                                                            else alert(res.message)
                                                                        }
                                                                    })
                                                                else
                                                                    addParticipant(auth.user_token, auth.user_uid, { students: [batches[batch].students[b]], coachPhone: auth.user.phone, batchName: batch }).then(res => {
                                                                        if (res?.code) alert(res.message);
                                                                    })
                                                                // uploadBatch({ students: [batches[batch].students[b]], coachPhone: auth.user.phone, batchName: batch })
                                                            }
                                                        }
                                                    }
                                                }}
                                                tickField={["Assessment 1", "Assessment 2", "Assessment 3", "Assessment 4"]}
                                                downloadStatusField={["Status"]}
                                                nonEditableField={["S.No.", "Status", "Assessment 1", "Assessment 2", "Assessment 3", "Assessment 4"]}
                                                hiddenField={["userId"]}
                                                editData={editData}
                                            />
                                        </div> : null}
                                    </div>

                                </>
                        }
                        {/* </div> */}
                    </div >

                </div >

                <UploadDialog
                    open={uploadDialogOpen}
                    closeEvent={setUploadDialogOpen}
                    batch={batches?.[batch]?.batchName}
                    setRefresh={setRefresh}
                />
                <AddCollaborator
                    open={addCollaboratorDialogOpen}
                    closeEvent={setAddCollaboratorDialogOpen}
                    user_token={auth.user_token}
                    user_id={auth.user_uid}
                    batch={batches[batch]}
                    setRefresh={setRefresh}
                />
            </div >
        </div >
    </>
}