import { Tabs, Tab, Dialog, Divider, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { initialiseBlog } from "../../application/redux_actions";
import { Link } from "react-router-dom";
import LeftNav from "../LeftNav";
import Resource from "./resource";
import "./style.css";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Label } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import pdf from "./pdf_icon.svg"
import video from './video_icon.svg'
import { useSelector } from "react-redux";
import { isBPUT } from "../../../utilities/common";

const usestyles = makeStyles((theme) => ({
  root: {
    width: '40%',
  },
  heading: {
    fontSize: 17,
    fontWeight: 700,
  },
  para: {
    fontWeight: theme.typography.fontWeightLight,
  },
  hara: {
    height: '2px',
    backgroundColor: "black"
  }
}));


let Blog = (props) => {
  let { blogModules, howTo, availableTags, selectedRole } = props.app;
  const [resourceId, setResourceId] = useState(null);
  const [resource__, setResource] = useState(null);
  const [resourcePop, setResourcePop] = useState(false);
  const [hTab, setHTab] = useState(0);
  const [vTab, setVTab] = useState(0);
  const [currentUnit, setCurrentUnit] = useState(0)
  const [vTabId, setVTabId] = useState(null);
  let modulesInAction = !hTab ? blogModules : howTo;
  let _modulesInAction = Object.keys(modulesInAction).filter(a => {
    return modulesInAction[a].courseId == selectedRole;
  });
  let mdKeys = _modulesInAction.sort((a, b) => {
    return modulesInAction[a].order - modulesInAction[b].order
  });
  console.log(mdKeys);

  const handleChange = (ev, newValue) => {
    ev.stopPropagation();
    setVTabId(newValue);
    setCurrentUnit(newValue)
  };
  useEffect(() => { initialiseBlog([selectedRole]) }, [])
  let md = modulesInAction[vTabId || mdKeys[0]];

  return <div id="resources" className="ir-tisa-font" style={{ display: "flex", height: "100%" }}>
    <LeftNav />
    <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, overflowY: 'hidden' }}>
      <div style={{ color: "#E4001B" }}>
        <div
          style={{ width: "100%", height: "100%", backgroundColor: "rgb(242, 242, 242)" }}
          className="tct-scrollable-darkgray overFlowYAuto"
        >
          <div className={usestyles.root} style={{
            margin: 0, width: 400, overflow: "auto", padding: 4
          }}>
            <Accordion style={{ margin: 0 }} disablegutters expanded={hTab == 0}
              elevation={0}
              onChange={() => { setHTab(hTab == 0 ? null : 0) }}
            >
              <AccordionSummary
                style={{ background: "rgb(242, 242, 242)" }}
                expandIcon={<ExpandMoreIcon />}
              >
                <div style={{ fontWeight: 700, fontSize: 17 }}>MODULE-WISE DEMOS</div>
              </AccordionSummary>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {mdKeys.map((mid, index) => {
                  let b = blogModules[mid];
                  return <AccordionDetails key={mid}
                    style={{ cursor: 'pointer', order: b.order, borderBottom: '1px solid #F1F1F1' }}
                    onClick={(ev) => { handleChange(ev, mid) }}
                    className={(currentUnit === mid) ? 'typo-text-active' : 'typo-text-inactive'}
                  >
                    <Typography style={{ display: 'flex', flexDirection: 'row', fontWeight: 900 }}>
                      {b.title}
                    </Typography>
                  </AccordionDetails>
                })}
              </div>
            </Accordion>
            {!isBPUT && <Accordion style={{ margin: 0 }} disableGutters expanded={hTab == 1}
              elevation={0}
              onChange={() => { setHTab(hTab == 1 ? null : 1) }}>
              <AccordionSummary
                style={{ background: "rgb(242, 242, 242)" }}
                expandIcon={<ExpandMoreIcon />}
              >
                <div style={{ fontWeight: 700, fontSize: 17 }}>HOW-TOS</div>
              </AccordionSummary>
              {Object.keys(howTo || {}).map((mid, index) => {
                let b = howTo[mid];
                return <div key={mid} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', borderBottom: '1px solid #F1F1F1' }}>
                  <AccordionDetails onClick={(ev) => handleChange(ev, mid)}
                    className={(currentUnit === mid) ? 'typo-text-active' : 'typo-text-inactive'}
                  >
                    <Typography style={{ fontWeight: 900 }}>
                      {b.title}
                    </Typography>
                  </AccordionDetails>
                </div>
              })}
            </Accordion>
            }
          </div>
        </div>
      </div>

      {hTab == null
        ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
          <div style={{ color: "#666", fontSize: 20 }}>Please select a type from left</div>
        </div>
        : <div style={{ flexGrow: 1, paddingTop: "24px" }} className="tct-scrollable-darkgray overFlowYAuto">
          <div style={{ padding: "0px 32px" }}>
            {hTab ? null : <div style={{ fontWeight: 700, fontSize: 16, marginTop: 8 }}>Module</div>}
            <div style={{ fontWeight: 700, fontSize: 20, marginBottom: 8 }}>{md?.title}</div>
            <div style={{ maxHeight: "80vh" }}>
              <div style={{ color: "#929292", fontSize: 16, margin: "16px 0px", borderBottom: "1px solid #CCC", paddingBottom: "12px" }}>
                {`${Object.keys(md?.medias || {}).length} ${hTab ? "How To Resources" : "Demo Videos"}`}
              </div>
              <div style={{
                paddingBottom: "8px", display: "flex", flexWrap: "wrap", flexGrow: 1
              }}>
                {Object.keys(md?.medias || {}).map((mediaId, i) => {
                  let media = md.medias?.[mediaId];
                  return <div
                    onClick={() => {
                      let unit = props.app.blogModules[vTabId || mdKeys[0]]
                        ? props.app.blogModules[vTabId || mdKeys[0]].medias[mediaId]
                        : props.app.howTo[vTabId || mdKeys[0]].medias[mediaId];
                      setResourceId(mediaId);
                      setResource(unit);
                      setResourcePop(true)
                    }}
                    style={{ width: hTab ? "100%" : "50%", display: "flex", borderBottom: "1px solid #CCC", paddingBottom: "25px", marginBottom: 20 }} key={mediaId} >
                    {hTab ? null : <div style={{ display: "flex", position: "relative", width: 123 }}>
                      <img src={media?.poster} style={{ width: 123, height: 82, borderRadius: 8 }} />
                      <img src={pdf} style={{ position: "absolute", width: 23, height: 23, left: 50, top: 30 }} />
                    </div>}
                    <div style={{ width: "calc(100% - 123px)", margin: "4px 8px", maxWidth: 462, fontSize: 16 }}>
                      <div style={{ fontSize: "1rem", fontWeight: "medium" }}>{media.description}</div>

                      {hTab ? <div style={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'center',
                        alignItems: 'center', marginTop: '2%', width: "max-content", padding: "10px",
                        height: 40, border: '1px solid #DADADA', borderRadius: '17px'
                      }}>
                        <div>{(media.type == 'video' || media.type == 'audio')
                          ? <img src={pdf} className='img_resource' />
                          : <img src={video} className='img_resource' />
                        }</div>
                        <div style={{ marginTop: '-3px', cursor: 'pointer' }}>{media.details}</div>
                      </div> : null}
                    </div>
                  </div>
                })}
              </div>

            </div>
          </div>
        </div>}

    </div>
    <Dialog open={resourcePop} fullScreen>
      <div style={{ position: "relative" }}>
        <Resource resourceId={resourceId} unit={resource__} />
        <Button style={{ right: 10, top: 10, position: "absolute" }}
          onClick={() => { setResourcePop(false) }}><CloseIcon /></Button>
      </div>
    </Dialog>
  </div >
}

const mapStateToProps = (state) => ({ auth: state.auth, app: state.app });
export default connect(mapStateToProps)(Blog);