import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import APConductDialog from '../ConductDialog';
import Icon from '@mdi/react';
import { mdiArrowRight, mdiClipboardArrowDown } from '@mdi/js';
import DownloadButton from './DownloadButton';

export default function ModuleCard({ index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app, estimatedTime, a, data, worksheetURL, prepare }) {
    const titleRef = useRef('');
    const { t } = useTranslation();
    const conductLink = <div className='ir-prepare-conduct-button h-100 ap-conduct-hover-color'>
        <div>{t('CONDUCT')}</div>
        <Icon path={mdiArrowRight}
            size={1}
            style={{ verticalAlign: "middle" }}
        />
    </div>

    return (
        <div key={description} className='d-flex w-100' style={{ margin: '3% 0' }} >

            <div className='ap-module-card-box-shadow ir-module-card overflow-hidden' style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column', width: '90%' }}>
                <div ref={titleRef} className='ir-module-card-title-container'>
                    <div className='ir-module-card-number'>{(Object.keys(data).length == 0 ? (index + 1) : ((a + 1) + '.' + (index + 1)))}</div>
                    <div className='ir-module-card-title'>{description}</div>
                </div>

                <div className='ap-module-card-img text-center'>
                    <div>
                        {prepare && <Link className='ir-prepare-conduct-button h-100 ap-prepare-hover-color' to={`/application/prepare/${moduleId}`} >
                            <div >{t("PREPARE")}</div>
                            <Icon path={mdiArrowRight}
                                size={1}
                                style={{ verticalAlign: "middle" }}
                            />
                        </Link>}

                        <APConductDialog
                            moduleId={moduleId}
                            title={title}
                            description={description}
                            index={index}
                            mainFilePath={mainFilePath}
                            user_token={user_token}
                            coach_id={coach_id}
                            testType="pre"
                            auth={auth}
                            app={app}
                            buttonTextComponent={conductLink}
                            buttonClassName=''
                            rootClassName=''
                            estimatedTime={estimatedTime}
                        />
                        {/* <div className='h-40 b-class download-button' style={{ width: "fit-content" }}> */}

                        {/* </div> */}
                    </div>
                </div>
                {worksheetURL && <DownloadButton
                    icon={mdiClipboardArrowDown}
                    text="Download worksheet"
                    href={worksheetURL} />}
                {/* <img src={mainFilePath} className='h-100' alt='mainFilePath' />
                    <div className="ir-prepare-conduct-button">
                        <div className="ap-text tablet-gothic">
                            <Link className='ap-prepare-hover ap-prepare-hover-color' to={`/application/prepare/${moduleId}`} style={{ color: 'white', fontSize: '1.5rem' }} >{t("PREPARE")} &nbsp;&nbsp; <span style={{ fontSize: 'xx-large' }}>&#129122;</span></Link>
                            <APConductDialog
                                moduleId={moduleId}
                                title={title}
                                description={description}
                                mainFilePath={mainFilePath}
                                user_token={user_token}
                                coach_id={coach_id}
                                testType="pre"
                                auth={auth}
                                app={app}
                                buttonTextComponent={conductLink}
                                buttonClassName='ap-prepare-hover ap-conduct-hover-color h-100 color-white font-size-1-5rem'
                                rootClassName='h-50'
                            />

                        </div>
                    </div> */}

            </div>
        </div>
    )
}
