import "babel-polyfill";
import React, { Component } from "react";
import Main from "./Main";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./Redux_Store";

class App extends Component {
  render() {
    let persistor = persistStore(store);
    return (
      <Provider store={store}>
        <PersistGate
          loading={
            <div>
              <p>Loading</p>
            </div>
          }
          onBeforeLift={() => { }}
          persistor={persistor}
        >
          <Router>
            <Main />
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
