import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from '../Redux_Store';
import Interaction from '../containers/application/Interaction';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Tooltip from '@material-ui/core/Tooltip';

import './style.css';

import QuizIcon from './assets/quiz_black_24dp.svg'
import { startPostSession } from '../containers/application/redux_actions';

const etah = (event) => {
    switch (event.eventName) {
        case "JOIN_SESSION":
            store.dispatch({ type: "STUDENT_JOINED", payload: event.eventData })
            break;
        case "ASSESSMENT_DONE":
            store.dispatch({ type: "ASSESSMENT_DONE", payload: event })
            break;
        default:
            console.log(event)
    }
}

export default ({ width, height, data, typPC, isFullScreen, engagementData, manageToolData, engagementStatus }) => {
    const { user_token } = useSelector((state) => state.auth)
    const app = useSelector((state) => state.app);

    const session = useSelector(state => state.app.createdSession)
    const sessionId = session["id"]
    const { studentJoinedList, assessmentDoneStudent } = useSelector(state => state.app)

    useEffect(() => {
        Interaction.startSession(sessionId, etah)
        startPostSession({ sessionId, user_token })

        return () => {
            Interaction.stopSession(sessionId)
        }
    }, [sessionId])

    const renderStudentNameList = () => {
        let tempArr = [];

        if (Object.values(studentJoinedList).length !== 0) {
            Object.values(studentJoinedList).map((student, index) => {
                tempArr.push(<>
                    <Tooltip title={<h3 style={{ color: 'white' }}>{student.username}</h3>} arrow key={student.studentId} className='ir-student-name-list'>
                        <FormControlLabel
                            style={{ margin: 0 }}
                            classes={{
                                label: 'ir-conduct-dialog-student-list-student-name'
                            }}
                            control={<Checkbox style={{ padding: '5px' }} color='default'
                                checked={Object.keys(assessmentDoneStudent).includes(student.userId)}
                                icon={<RadioButtonUncheckedIcon style={{ fontSize: 28 }} />} checkedIcon={<CheckCircleIcon style={{ color: '#3ACA0F', fontSize: 28 }} />} />}
                            label={student.username}
                        />
                    </Tooltip>
                </>)
            })

            if (tempArr?.length % 5 !== 0) {
                let tempLen = tempArr?.length % 5;
                let diff = 5 - tempLen;
                if (tempLen) {
                    for (let i = 0; i < diff + 5; i++)
                        tempArr.push(<div key={'dummy div ' + i} className='ir-student-name-list'>&nbsp;</div>)
                }
            }
            return tempArr;
        }
        else
            return null;
    }

    return (
        <div style={{ width, height, background: '#EEEEEE' }}>
            <div className='w-100 h-100' style={{ padding: '0 2% 2%' }}>
                <div className='h-35 overFlowYAuto tct-scrollable-darkgray'>
                    <div className='post-session-title'>
                        <img src={QuizIcon} style={{ width: '2em', marginRight: '0.5em' }} />{data.title}
                    </div>
                    <div className='d-flex' style={{ margin: '1em 0' }}>
                        <div className='ir-post-session-assessment-code'>
                            {
                                typPC !== "Prepare" && app?.otpData?.otp && <span>Assessment Code: <span className='ir-post-session-otp' >{app?.otpData?.otp}</span></span>
                            }
                        </div>
                        <div className='ir-post-session-module-name'>
                            {data?.myAppModules?.map(item => <div key={item} className='post-session-module-names'>{item}</div>)}
                        </div>
                    </div>
                </div>
                <div className='ir-post-session-name-list' style={{ height: isFullScreen ? '65%' : '52%' }}>
                    <div style={{ fontSize: '0.8em', height: '15%' }}>{Object.keys(app?.assessmentDoneStudent).length}  Participants completed assessment</div>
                    {/* <div style={{ fontSize: '0.8em', height: '15%' }}>({Object.keys(app?.assessmentDoneStudent).length} of {Object.keys(app?.studentJoinedList).length}) Participants completed assessment</div> */}
                    <div className='d-flex flex-wrap overFlowYAuto tct-scrollable-darkgray h-85 align-content-baseline'>
                        {renderStudentNameList()}
                    </div>
                </div>
                <div style={{ fontSize: isFullScreen ? '0.8em' : '0.7em', padding: '1em' }}>Are there participants who could not finish the assessments? Drop their names and phone numbers in the help chat icon. Our support team will connect soon. (Note: Help icon is available on bottom right corner in exit full screen mode) </div>
            </div>
        </div>
    );
}