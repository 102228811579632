import { Button } from "@material-ui/core";
import { mdiCellphone, mdiEmail } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect } from "react";
import EmailPassword from "./EmailPassword";
import MobileOTP from "./MobileOTP";

export default ({ auth, app, mobileType, setMobileType }) => {
  return (
    <>
      <div
        className="ap-login-field-container box-shadow tct-scrollable-darkgray overFlowYAuto"
        style={{ maxHeight: "85vh", padding: "6%" }}
      >
        {mobileType === "mobileOTP" ? (
          <MobileOTP auth={auth} app={app} setMobileType={setMobileType} />
        ) : (
          <EmailPassword />
        )}
      </div>
      <div style={{ marginTop: "5%" }}>
        <div
          style={{
            textAlign: "center",
            fontFamily: "tablet-gothic",
            fontWeight: "500",
          }}
        >
          or Login Using
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: "5%",
          }}
        >
          <Button
            style={{
              width: "100%",
              margin: "12px 0px ",
              backgroundColor: "#dbe0e3 ",
              padding: " 12px ",
              borderRadius: "17px ",
              fontWeight: "600 ",
              fontFamily: "'tablet-gothic' ",
              fontSize: "1.25rem ",
              display: mobileType === "mobileOTP" ? "none" : "block",
            }}
            onClick={() => setMobileType("mobileOTP")}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Icon path={mdiCellphone} size={1} />{" "}
              <div style={{ margin: "-1.5% 0 0 2%" }}>Phone</div>
            </div>
          </Button>

          <Button
            style={{
              width: "100%",
              margin: "12px 0px ",
              backgroundColor: "#dbe0e3 ",
              padding: " 12px ",
              borderRadius: "17px ",
              fontWeight: "600 ",
              fontFamily: "'tablet-gothic' ",
              fontSize: "1.25rem ",
              display: mobileType === "emailPassword" ? "none" : "block",
            }}
            onClick={() => setMobileType("emailPassword")}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Icon path={mdiEmail} size={1} />{" "}
              <div style={{ margin: "-1.5% 0 0 2%" }}>Email</div>
            </div>
          </Button>
          {/* <Button style={{ border: "1px solid black" }}>
                                <Icon path={mdiGoogle} size={1} /> Google</Button> */}
        </div>
      </div>
    </>
  );
};
