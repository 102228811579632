import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { getEngagement } from "../../containers/application/redux_actions";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Button, IconButton, LinearProgress } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import Start_N_Stop from "../Start_N_Stop";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import "./barStyle.css";
import ProgressBar from "../ProgressBar";

// import { easeLinear } from "d3";

Chart.plugins.register(ChartDataLabels);

export default ({
    width,
    height,
    engagementData,
    manageToolData,
    isFullScreen,
    data,
    sessionId,
    engagementStatus,
    typPC,
    // responses,
    currentEngagement,
    uniqueStudentsName,
    typeOfEngagment,
}) => {
    let responseCount = useSelector((state) => state.app.responseCount[data.engagement]);
    let studentJoinedList = useSelector((state) => state.app.studentJoinedList);
    let responses = useSelector((state) => state.app.response[data.engagement]);
    let names = useSelector((state) => state.app.names[data.engagement]);
    let [show, setShow] = useState(false);
    let engConfig = currentEngagement?.questions?.[0]
    // console.log("engConfig", engConfig,currentEngagement)
    let answers = [];
    let studentslabel = [];
    let Obj = {};
    let labelObj = {};
    let newObj = {};

    let totalStudents = Object.values(studentJoinedList)?.length;
    let typeofEng = engConfig?.type;
    let options = Object.values(engConfig?.answers || {}).map(option => option?.option)

    if (typeofEng && responseCount) {
        Object.values(responseCount).map((el) => {
            Object.values(el).map((ts) => {
                if (typeofEng == "MultipleCorrect") {
                    answers.push(ts);
                } else {
                    Object.values(ts).map((fs) => {
                        answers.push(fs);
                    });
                }
            });
        });

        if (names) {
            studentslabel = names.map((el, i) => {
                if (answers[i])
                    return Object.assign({}, { username: names[i], ans: answers[i] });
            });

        }
    }
    if (studentslabel.length > 0) {
        uniqueStudentsName && uniqueStudentsName.map((el) => {
            newObj[el] = [];
            let ans = [];
            Object.values(studentslabel).map((ts, k) => {
                if (studentslabel[k].ans) {
                    if (el == studentslabel[k].username) {
                        ans.push(studentslabel[k].ans);
                    }
                    newObj[el] = ans[ans.length - 1];
                }
            });
        });
    }

    options.map((opt, i) => {
        let labArr = [];
        Object.keys(newObj).map((keys, k) => {
            if (typeofEng == "MultipleCorrect" && newObj[keys]) {
                newObj[keys].map((ansk, q) => {
                    if (opt == ansk) {
                        labArr.push(keys);
                    }
                });
            } else if (opt == newObj[keys]) {
                labArr.push(keys);
            }
        });
        labelObj[opt] = labArr;
    });
    // console.log("labelObj", labelObj)
    const scenario = {
        [data?.engagement]: {
            "responses": responses,
            "uniqueStu": uniqueStudentsName,
            "totalStu": studentJoinedList
        }
    }
    function Progress_bar(percentage) {
        const Parentdiv = {
            height: "100%",
            width: "100% ",
            backgroundColor: "#F5F5F5",
            // borderRadius: `${br}`,
        };

        const Childdiv = {
            height: "100%",
            width: `${percentage}%`,
            // width: "10%",
            backgroundColor: "#FFE39A",
            // borderRadius: 40,
            textAlign: "right",
        };

        return (
            <div style={Parentdiv}>
                <div style={Childdiv}></div>
            </div>
        );
    }

    let progessBarValue =
        (uniqueStudentsName?.length / totalStudents) * 100;

    return (
        <div>
            {options && show && uniqueStudentsName && (
                <div
                    className="horizontal-poll"
                    style={{
                        width: !isFullScreen ? "250px" : "50%",
                        height: "max-content",
                        // justifyContent: "center",
                    }}
                >
                    <IconButton
                        style={{
                            position: "absolute",
                            top: "-15px",
                            right: "10px",
                            marginTop: "2%",
                            width: "12%",
                            padding: "0px !important",
                        }}
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        <CloseSharpIcon fontSize="small" />
                    </IconButton>

                    {options.map((op, i) => {
                        let eachPBV;
                        let barValue;
                        if (Obj && typeofEng) {
                            Object.keys(labelObj).map((keys, k) => {
                                if (keys == op) {
                                    barValue =
                                        (labelObj[keys].length /
                                            uniqueStudentsName?.length) *
                                        100;
                                }
                            });
                            barValue % 1 == 0 ? eachPBV = barValue : eachPBV = barValue.toFixed(2);
                        }

                        return (
                            <div className="ansContainer" id={op}>
                                <div
                                    className="inner-ansContainer"
                                    id={op}
                                    style={{
                                        fontSize: !isFullScreen ? "0.8rem" : "1.2rem",
                                    }}
                                >
                                    <div style={{ width: "80%" }}>{op}</div>
                                    <div>{`${eachPBV || 0}%`}</div>
                                </div>
                                <div id={op}>
                                    <a data-tip data-for={op}>
                                        <div
                                            id="op"
                                            style={{
                                                width: "100%",
                                                height: !isFullScreen ? "12px" : "15px",
                                                marginTop: "1%",
                                            }}
                                        >
                                            {Progress_bar(eachPBV || 0)}
                                        </div>
                                    </a>

                                    <ReactTooltip id={op} aria-haspopup="true" role="example">
                                        <ul>
                                            {labelObj[op] && labelObj[op].length > 0 ? (
                                                labelObj[op].map((users) => {
                                                    return <li key={op}>{users}</li>;
                                                })
                                            ) : (
                                                <li key={op}>No User</li>
                                            )}
                                        </ul>
                                    </ReactTooltip>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <ProgressBar
                labelObj={labelObj}
                typPC={typPC}
                scenarioId={scenario}
                data={data}
                engagementData={engagementData}
                manageToolData={manageToolData}
                isFullScreen={isFullScreen}
                responses={responses}
                currentEngagement={currentEngagement}
                uniqueStudentsName={uniqueStudentsName}
                sessionId={sessionId}
                progessBarValue={progessBarValue}
                setShow={setShow}
                // answers={newDipLabel}
                totalStudents={totalStudents}
                engagementStatus={engagementStatus}
            />
        </div>
    );
};
