const languages = {
    "en": "English",
    "or": "ଓଡିଆ",
    "hi": "हिन्दी",
    "gu": "ગુજરાતી",
    "bn": "বাংলা",
    "mr": "मराठी",
    "kn": "ಕನ್ನಡ",
}

export default languages