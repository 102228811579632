import { Button, FormControl } from "@material-ui/core";
import React, { useState } from "react";
import DropDown from "./DropDown";
import InputField from "./Input";
import SeachableDropDown from "./SeachableDropDown";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Redirect } from "react-router";
import { checkAccesscode, registerUser } from "../../auth/redux_actions";

export default ({ auth, app }) => {
  const [redirect, setRedirect] = useState();
  const [error, setError] = useState({});

  const [values, setValues] = useState({ phone: app.userValues.phone });
  const [registerStatus, enableRegister] = useState(true);

  const [accesscodeData, setAccessCodeData] = useState();

  const handleChange = (event, field) => {
    setValues((oldValue) => ({ ...oldValue, [field]: event.target.value }));
  };

  const verifyCode = (event) => {
    if (!values?.email || values.email == "") {
      setError({ email: "Email cannot be empty" });
    } else {
      setError({ email: undefined });
    }
    if (!values?.fullname || values.fullname == "") {
      setError({ fullname: "Full Name cannot be empty" });
    } else {
      setError({ fullname: undefined });
    }
    checkAccesscode(values?.accesscode).then((data) => {
      setAccessCodeData(data);
    });
  };

  const handleFieldChange = (event, metaKey, value) => {
    setValues((oldValue) => ({
      ...oldValue,
      meta: {
        ...oldValue?.meta,
        [metaKey]: value,
      },
    }));
    enableRegister(true);
  };

  const getField = (field) => {
    if (registerStatus && field.mandatory && !values?.meta?.[field.metaKey]) {
      enableRegister(false);
    }
    // else {enableRegister(true)}
    switch (field.fieldType) {
      case "dropdown":
        return (
          <DropDown
            options={field.values}
            label={field.fieldName}
            // placeholder="Please Select"
            value={values?.meta?.[field.metaKey] || ""}
            onChange={(event) =>
              handleFieldChange(event, field.metaKey, event.target.value)
            }
          />
        );
      case "searchable_dropdown":
        return (
          <SeachableDropDown
            options={field.values}
            getOptionLabel={(option) => option}
            onChange={(event, value) =>
              handleFieldChange(event, field.metaKey, value)
            }
            label={field.fieldName}
          />
        );
      case "fixed":
        if (!values?.meta?.[field.metaKey])
          handleFieldChange(undefined, field.metaKey, field.values[0]);
      default:
        return (
          <InputField
            type="Text"
            field={field.metaKey}
            values={
              field.fieldType == "fixed"
                ? { [field.metaKey]: field.values[0] }
                : values?.meta
            }
            disabled={field.fieldType == "fixed"}
            placeHolder={field.fieldName}
            handleChange={(event) =>
              handleFieldChange(event, field.metaKey, event.target.value)
            }
          />
        );
    }
  };

  if (redirect) return <Redirect to={redirect} />;
  else
    return (
      <div
        className="ap-login-field-container box-shadow tct-scrollable-darkgray overFlowYAuto"
        style={{ maxHeight: "85vh", width: "450px", padding: "6%" }}
      >
        <div style={{ overflowY: "auto" }}>
          <ArrowBackIcon
            onClick={() => setRedirect("/")}
            style={{ verticalAlign: "middle", marginRight: "2%" }}
          />
          <span>Setting up your account</span>
          <InputField
            type="Text"
            field="phone"
            values={app.userValues}
            placeHolder="Enter 10-digit Mobile number"
            disabled
          />
          <InputField
            type="Text"
            field="email"
            values={values}
            placeHolder="Your email id"
            handleChange={handleChange}
            required
            error={error}
          />
          <InputField
            type="Text"
            field="fullname"
            values={values}
            placeHolder="Your full name"
            handleChange={handleChange}
            required
            error={error}
          />
          <span style={{ fontSize: "0.9rem" }}>
            Please contact your trainer or teacher for Program Access code
          </span>
          <InputField
            type="Text"
            field="accesscode"
            values={values}
            placeHolder="Access code"
            handleChange={handleChange}
            required
            error={error}
          />

          {accesscodeData?.code && (
            <span style={{ color: "red" }}>{accesscodeData.message}</span>
          )}

          <Button className="login-button-ap" onClick={verifyCode}>
            Verify code
          </Button>

          {accesscodeData?.accessCode && (
            <>
              <div style={{ fontSize: "0.9rem" }}>Registering you for -</div>
              <div style={{ fontWeight: "bold", fontSize: "1rem" }}>
                {accesscodeData.title}
              </div>
              <div style={{ fontSize: "0.9rem" }}>
                {accesscodeData.subTitle}
              </div>

              {accesscodeData.fields.map((field, index) => (
                <div style={{ margin: "15px 0px" }} key={index}>
                  {getField(field)}
                </div>
              ))}
              <Button
                className="login-button-ap"
                disabled={!registerStatus}
                onClick={() => registerUser({ ...values, meta: { [accesscodeData.meta_name]: values.meta } })}
              >
                Register
              </Button>
            </>
          )}
        </div>
      </div>
    );
};
