import React, { Component } from 'react';
import { Document, Page } from "react-pdf/dist/entry.webpack";
import Glide from '@glidejs/glide';
import "./book.css"
import { logEvent } from "../../../containers/application/redux_actions"
import { modules } from "./modules"
import { connect } from 'react-redux';

const leftArrow = <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="#FFFFFF"
  width="36"
  height="36"
  viewBox="0 0 18 18"><path d="M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z" /></svg>
const rightArrow = <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="#FFFFFF"
  width="36"
  height="36"
  viewBox="0 0 18 18"><path d="M7.5 4.5L6.44 5.56 9.88 9l-3.44 3.44L7.5 13.5 12 9z" /></svg>

const isBrowser = () => typeof window !== 'undefined' && window.document !== undefined;

class BookMultiPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
      padding: 4,
      ratingValue: 0
    };
    this.onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
    }
    this.zoomPage = (ev) => {
    }
    this.onRatingChange = ratingValue => {
      this.setState({ ratingValue })
    }
  }

  componentWillMount() {
    let docRatio = this.props.docRatio
    // || 1.55; //default is potrait 0.6333
    if (isBrowser()) {
      var w = window.innerWidth * 0.92;
      var h = window.innerHeight;
      var deviceRatio = w / h;
      this.setState({ deviceRatio })
      if (w / h >= docRatio) {
        // This need to be fixed to the height
        this.setState({
          width: (h * docRatio),
          height: h
        });
      } else {
        //This need to be fixed to width
        this.setState({
          width: w,
          height: w / docRatio - 48
        });
      }
    }
  }

  componentDidUpdate() {

    if (this.state.numPages > 0) {
      var glide = new Glide('.glide', { perView: 1 })
        .mount()
        .on('run', (ev) => {
          if (isBrowser()) {
            document
              .getElementById('current-page')
              .innerHTML = glide.index + 1;
          }
        });
    }
  }

  componentDidMount() {
    const { token, uid, unit } = this.props
    const { id, title } = unit
    logEvent(token, uid, "RESOURCE_ACCESSED", { unitId: id, title })
  }

  render() {
    const { duringLoad, src, unit, token, uid } = this.props;
    const { id, title } = unit
    const { numPages, width, height, padding, ratingValue } = this.state;
    if (this.state.numPages > 0) {
      var glide = new Glide('.glide', { perView: 1 })
        .mount()
        .on('run', (ev) => {
          if (isBrowser()) {
            document
              .getElementById('current-page')
              .innerHTML = glide.index + 1;
          }
        });
    }
    if (isBrowser()) {
      return (
        <div className='_book-cntr'>
          <Document
            loading={duringLoad}
            className='document-cntr'
            file={src}
            onLoadSuccess={this.onDocumentLoadSuccess}>
            <div className="glide">
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">
                  {populatePages(numPages, width, height, padding, { ratingValue, onRatingChange: this.onRatingChange })}
                </ul>
              </div>
              <div
                className="glide__arrows"
                data-glide-el="controls"
                style={{
                  width
                }}>
                <button className="glide__arrow glide__arrow--left" data-glide-dir={"<"} onClick={() => {
                  return modules[id] && modules[id][glide.index + 1] && logEvent(token, uid, "MODULE_PREPARED", { unitId: id, title, moduleId: modules[id][glide.index + 1]["moduleId"], progress: modules[id][glide.index + 1]["progress"] })
                }}>{leftArrow}</button>
                <button className="glide__arrow glide__arrow--right" data-glide-dir={">"} onClick={() => {
                  return modules[id] && modules[id][glide.index + 1] && logEvent(token, uid, "MODULE_PREPARED", { unitId: id, title, moduleId: modules[id][glide.index + 1]["moduleId"], progress: modules[id][glide.index + 1]["progress"] })
                }}>{rightArrow}</button>
              </div>
            </div>
            <div className="page-numbers">
              <span id='current-page'>1</span>/<span id='number-of-pages'>{numPages}</span>
            </div>
            {/* <div className="zoom-controls">
              <span id='zoom-up'>+</span>/<span id='zoom-down'>-</span>
            </div> */}
          </Document>
        </div>
      )
    }
    return null;
  }
}

function populatePages(numPages, width, height, padding, feedback) {
  let canvasWidth = width - 2 * padding;
  let array_pages = [];
  for (let i = 1; i <= numPages; i++) {
    array_pages.push(
      <li
        key={'pg-' + i}
        className='page-cntr glide__slide'
        style={{
          width,
          height
        }}><Page
          pageNumber={i}
          width={canvasWidth}
          renderTextLayer={false}
          onRenderSuccess={() => { }} /></li>
    )
  };
  return array_pages;
}

const mapStateToProps = state => ({ app: state.app, auth: state.auth });
export default connect(mapStateToProps, null)(BookMultiPage);