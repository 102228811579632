import React from "react"
import { Autocomplete } from "@material-ui/lab"
import { TextField } from "@material-ui/core"

export default ({ options, getOptionLabel, onChange, label, value }) => {
    return <Autocomplete
        value={value}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        // style={{ width: 300 }}
        renderInput={params => (
            <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
            />
        )}
    />
}