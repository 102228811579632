import React from "react"
import { FormControlLabel as MuiFormControlLabel, Radio as MuiRadio, RadioGroup as MuiRadioGroup } from "@material-ui/core"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withStyles } from "@material-ui/styles";
import store from "../../../Redux_Store";
import { updateUser } from "../../application/redux_actions";

const RadioGroup = withStyles({
 root: {
  padding: '1.5rem',
  margin: '1rem 0',
  fontWeight: 'bold !important',
  transition: 'all ease-in-out .3s',
  flexDirection: 'row !important',
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  background: "#F2F2F2 0% 0% no-repeat padding-box",
  border: "2px solid #D3D3D3",
  borderRadius: "10px",
  "& svg": {
   width: '2rem',
   height: '2rem',
  },
  "&:hover": {
   background: "#FFEBE7 0% 0% no-repeat padding-box",
   border: "4px solid #BA1F00",
   borderRadius: "10px",
   color: "#BA1F00 !important",
   "& svg": {
    width: '2rem',
    height: '2rem',
    fill: "#BA1F00 !important"
   }
  },
  "&:checked": {
   color: "red"
  },
 },
})(MuiRadioGroup)

const Radio = withStyles({
 root: {
  color: "black",
  '&$checked': {
   color: "#BA1F00 !important",
  },
  "&:hover": {
   color: "green !important"
  }
 },
 checked: {},
})(MuiRadio)

const FormControlLabel = withStyles({
 label: {
  fontSize: "1.2rem",
  fontWeight: 500,
  // color: '#666666',
 }

})(MuiFormControlLabel)

export default ({ label, mIndex, setMindex, setMswitch, value }) => {
 return <RadioGroup value={mIndex} className='ir-role-radio-text' onClick={(event) => {
  let inputs = event.target.getElementsByTagName("input")
  let value = event.target?.value ? event.target?.value : inputs[0]?.value
  if (event.target?.value || (inputs.length > 0 && inputs[0]?.value)) {
   setMindex(value);
   store.dispatch({ type: "CHOOSE_ROLE", payload: value });
   setMswitch(false);
  }
 }}>
  <FormControlLabel className='ir-role-radio-text' value={value} label={label} control={<Radio />} />
  <ArrowForwardIcon />
 </RadioGroup>
}