import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import languages from "./languageList"
import { createTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import './style.css'
import { select } from 'd3-selection';



export default ({ selectedLang, show, onLanguageChange, langugeList, isFullScreen }) => {
  let theme = createTheme({
    overrides: {
      MuiSelect: {
        root: {
          borderRadius: '8px !important',
          backgroundColor: isFullScreen ? '#6b6b6b' : 'white',
          color: isFullScreen ? 'white' : '#6b6b6b',
          '&:focus': {
            borderRadius: '8px',
            backgroundColor: isFullScreen ? '#6b6b6b !important' : 'white !important',
          }
        },
        icon: {
          right: '7px',
          fill: isFullScreen && 'white'
        }
      },
      MuiMenu: {
        root: {
          color: isFullScreen && 'white'
        },
        paper: {
          // backgroundColor: '#6b6b6b',
          backgroundColor: isFullScreen ? '#6b6b6b' : 'white',
          color: isFullScreen ? '#ffffff' : '#6b6b6b',

        }
      },
      MuiList: {
        root: {
          padding: '0px 13px !important',
          backgroundColor: 'transparent !important',
        }
      },
      MuiListItem: {
        root: {
          backgroundColor: 'transparent',
          borderRadius: '8px',
          fontSize: "20px !important",
          '&:hover': {
            color: '#ba1f00 !important',
            background: '#FFE4DF !important',
            borderRadius: '8px',
          },
        },
        button: {
          backgroundColor: 'transparent',
          margin: '10px 0px',
          fontSize: " 16px !important",
        },
      },
    }
  })



  const [age, setAge] = useState(selectedLang);
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    setAge(event.target.value);
    onLanguageChange(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {langugeList?.length > 0 ?
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: '10px',
        }}>
          <ThemeProvider theme={theme} >
            <Select
              style={{ borderRadius: '8px', }}
              defaultValue={selectedLang}
              value={selectedLang}
              MenuProps={{ keepMounted: true, disablePortal: true }}
              classes={{ 'root': 'selectRoot', }}
              onChange={handleChange}
              displayEmpty
              disableUnderline
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {/* <MenuItem style={{ fontStyle: "normal !important" }} value="">
            <>English</>
          </MenuItem> */}
              {langugeList.map(lng => {
                // if (lng != 'en')
                return <MenuItem key={lng} value={lng}><span>{languages[lng]} </span></MenuItem>
              })}
            </Select>
          </ThemeProvider >
        </ div > : null}
    </>
  );
}

