import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import Logout from "../auth/Logout";
import Register from "./Auth/Register";
import "./style.css";
import { useSelector } from "react-redux";
import PegogLogo from "./Auth/assets/pedgog-logo.svg";
import IllumineLogo from "./Auth/assets/illumine-logo.svg";
import { Redirect } from "react-router";
import Login from "./Auth/Login";
import { Button } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiEmail, mdiGoogle, mdiCellphone } from "@mdi/js";

export default ({}) => {
  const { auth, app } = useSelector((state) => state);
  const [mobileType, setMobileType] = useState("mobileOTP");

  return (
    <div className="flex-center h-100">
      <div style={{ position: "absolute", bottom: "4%", left: "2%" }}>
        <img src={IllumineLogo} alt="IllumineLogo" style={{ width: "65px" }} />
        <div
          className="ap-gray-color-1 ir-copyright"
          style={{ fontSize: "0.85rem !important", margin: "0" }}
        >
          Copyright 2019-22; Illumine. All rights reserved.
        </div>
      </div>
      <div className="ir-login-container">
        <img
          src={PegogLogo}
          style={{
            marginRight: "10%",
            marginTop: "auto",
            marginBottom: "auto",
          }}
          alt="pegogLogo"
        />
        <div className="w-35">
          <div
            // className="ap-login-field-container box-shadow tct-scrollable-darkgray overFlowYAuto"
            className=""
            style={{ maxHeight: "85vh" }}
          >
            <Switch>
              <Route
                path="/auth/login"
                render={() => (
                  <Login
                    app={app}
                    auth={auth}
                    mobileType={mobileType}
                    setMobileType={setMobileType}
                  />
                )}
              />
              <Route path="/auth/logout" component={Logout} />
              <Route
                path="/auth/register"
                render={() => <Register app={app} auth={auth} />}
              />
              {/* <Route path="/auth/forgotpassword" component={ForgotPassword} />
                            <Route path="/auth/resetpassword" component={ResetPassword} /> */}
              <Route
                path="/"
                render={() => (
                  <Login
                    app={app}
                    auth={auth}
                    mobileType={mobileType}
                    setMobileType={setMobileType}
                  />
                )}
              />
            </Switch>
          </div>

          <div className="ap-gray-color-1 text-center ir-copyright">
            By clicking on Login, I accept the{" "}
            <a
              href="https://illumine.in/terms-of-use.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>{" "}
            &{" "}
            <a
              href="https://illumine.in/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      {auth?.isLoggedIn && (
        <Redirect
          to={{
            pathname: "/application",
          }}
        />
      )}
    </div>
  );
};
