import React, { useEffect, useState } from "react"
import { Button, CircularProgress, Dialog, LinearProgress } from "@material-ui/core"
import Table from "./Table"
import Dropzone from "./Dropzone"
import Export from "./Export"
import Icon from "@mdi/react";
import readXlsxFile from "read-excel-file"
import { isArrayEqual } from "../../../utilities/common"
import { uploadBatch } from "../../application/redux_actions"
import { useSelector } from "react-redux"
import { mdiDownload, mdiUpload } from '@mdi/js';

export default ({ open, closeEvent, batch, refresh, setRefresh }) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user_token, user_uid, user } = useSelector((state) => state.auth)

    useEffect(() => {
        readXlsxFile(files[0]).then((rows) => {
            if (isArrayEqual(Object.keys(data[0]), Object.values(rows[0]))) {
                const students = rows.filter((row, index) => index != 0).map((student) => ({
                    fullname: student[1],
                    phone: student[2].toString(),
                    division: student[3],
                    zone: student[4],
                    designation: student[5],
                    group: student[6]
                }))

                setLoading(true);
                uploadBatch(user_token, user_uid, { students, coachPhone: user.phone, batchName: batch, meta: { division: students[0].division, zone: students[0].zone } }).then((res) => {
                    setLoading(false);
                    if (res?.code)
                        alert(res.message)
                    else {
                        alert("Upload Done")
                        setRefresh(true)
                    }
                })
            } else
                alert("Uploaded data is not in correct format")
        })
    }, [files])

    const data = [{
        "S.No.": 1,
        "Participant Name": "Madhan Prakash",
        "Contact number": 9988776655,
        "Division": "PRYJ",
        // "Division": "Madurai",
        "Zone": "NCR",
        "Designation": "Clerk",
        "Group": "SM"
    }]

    return <Dialog open={open} fullWidth style={{ maxWidth: "100%" }} PaperProps={{ style: { maxWidth: loading ? "20%" : "100%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 5px 10px #00000021", borderRadius: "29px" } }}>
        <div style={{ margin: "2%" }}>
            <div style={{ cursor: "pointer", float: "right", marginLeft: "1%" }} onClick={() => closeEvent(false)}>X</div>
            {loading ? <div style={{ width: '100%', height: 75, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                Uploading...
                <CircularProgress />
            </div>
                : <><Dropzone files={files} setFiles={setFiles} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    <div style={{ color: "#636363", fontSize: "22px", textDecoration: "underline", letterSpacing: "0.22px" }} className="fw-bold">
                        <Icon path={mdiUpload}
                            title="Upload File"
                            size={1}
                        /> Upload the file here</div>
                    <div>You can drag and drop a file here, or click on the upload button to upload from your computer.</div>
                </Dropzone>
                    <div style={{ marginTop: "2%" }}>
                        <span style={{ fontSize: "22px", lineHeight: "28px", fontWeight: "bold", letterSpacing: "0.22px", color: "#1F1F1F" }}>Please upload the list in the following format.</span>
                        <Export
                            element={<Button style={{ float: "right", fontWeight: "600", fontSize: "18px", lineHeight: "28px", letterSpacing: "0.18px" }}> <Icon path={mdiDownload} size={1} /> Download Format</Button>}
                            data={data}
                            filename="format.xlsx"
                            SheetName="Sheet 1"
                        />
                    </div>
                    <div className="batch-card w-auto" style={{ margin: '5px 0' }}>
                        <Table data={data} showAction={false} />
                    </div>
                    <div style={{ margin: "0 5%", lineHeight: 1.2, letterSpacing: "0.18px", color: "#1F1F1F" }}>
                        <span>While you fill up the list in given format - </span>
                        <ol>
                            <li>Please write the First name and Last name in the same box</li>
                            <li>When entering the mobile number, kindly fill in only the 10-digit number without zero or country code </li>
                            <li>Please make sure you have entered the correct mobile number of the participant. As they will not be able to register if the information is incorrect.</li>
                        </ol>
                    </div> </>}
        </div >
    </Dialog >
}