import React, { useRef, useState, useEffect } from 'react'
import LeftNav from '../LeftNav'
import './style.css'
import APModuleCard from './ModuleCard'
import ModuleLogo from './assets/programvisual.svg';
import LoadingSpinner from './assets/Dual Ring-1s-79px.svg';
import Icon from '@mdi/react';
import {
  mdiAutorenew,
  // mdiAccountGroup,
  //  mdiArrowRight 
} from '@mdi/js';
import Radio from './radio';
import HomeIcon from "./assets/home-icon.png"
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
// import { RadioGroup } from '@material-ui/core';
import store from '../../../Redux_Store';
import i18next from 'i18next';
import { isBPUT, isIR } from "../../../utilities/common";
import LanguageSelection from '../../../components/LanguageSelection';

// import ProgramImg from './assets/rail-karmayogi-mascot.svg'
// import { isBPUT, isIR } from '../../../utilities/common';
// import { SignalCellularNoSimOutlined } from '@material-ui/icons';

export default ({ app, auth, menuItems, menuItems1 }) => {

  let { selectedRole, availableTags } = app;
  const { t } = useTranslation();
  let groups = {}

  const tagDetail = selectedRole && availableTags?.filter(tag => tag.id == selectedRole)?.[0]

  
  const availableModules = menuItems.filter((el, i) => {
    if (el.dataSet.length > 0)
      return true
    return false
  });

  // console.log(selectedRole, availableTags, availableModules)

  function showModules(s, i, z) {
    // let b = Object.values(groups).map(e=>e.subModules.length)
    let { title, description, id, mainFilePath, uniqueAboutIt, estimatedTime, worksheetURL, prepare } = s
    return <APModuleCard
      // NumMod={b}
      data={groups}
      a={z}
      key={i}
      index={i}
      title={title}
      description={description}
      mainFilePath={HomeIcon}
      moduleId={id}
      uniqueAboutIt={uniqueAboutIt}
      user_token={auth.user_token}
      coach_id={auth.user_uid}
      auth={auth}
      app={app}
      estimatedTime={estimatedTime}
      worksheetURL={worksheetURL}
      prepare={prepare}
    />
  }

  function show1Modules(el, i) {
    let { title, description, id, mainFilePath, uniqueAboutIt, estimatedTime, worksheetURL, prepare } = el
    return <APModuleCard
      data={groups}
      key={i}
      index={i}
      title={title}
      description={description}
      mainFilePath={HomeIcon}
      moduleId={id}
      uniqueAboutIt={uniqueAboutIt}
      user_token={auth.user_token}
      coach_id={auth.user_uid}
      auth={auth}
      app={app}
      estimatedTime={estimatedTime}
      worksheetURL={worksheetURL}
      prepare={prepare}
    />
  }
  const [mIndex, setMindex] = useState(app.selectedRole?.length > 1
    ? app.selectedRole : availableModules?.[0]?.id || null);
  useEffect(() => {
    if (app.selectedRole?.length < 2 && availableModules?.[0]?.id) {
      setMindex(availableModules?.[0]?.id)
      store.dispatch({ type: "CHOOSE_ROLE", payload: availableModules?.[0]?.id });
    }
  }, [app.selectedRole?.length, availableModules?.[0]?.id])

  const [mSwitch, setMswitch] = useState(false);
  const [mSwitchSpin, setMSwitchSpin] = useState(false);
  const [tagImg, setTagImg] = useState({})
  const userNameRef = useRef(null)
  const [selectedLang, setSelectedLang] = useState(i18next?.language ? i18next?.language : "en");
  const executeScroll = () => userNameRef.current.scrollIntoView({ behavior: 'smooth' });
  let rel_Modules = availableModules?.filter(a => a.id == mIndex)?.[0];
  const handleSwitch = (index) => {
    // setMindex(index);
    setMswitch(!mSwitch)
    setMSwitchSpin(true)
    setTimeout(() => {
      setMSwitchSpin(false)
    }, 250)
    executeScroll();
  }

  availableModules.map(e => {
    if (rel_Modules?.id === e?.id) {
      e.dataSet.map(d => {
        return d.moduleGroupId.map(group => {
          groups[group._id] = {
            id: group._id,
            title: group.title,
            subModules: e.dataSet.filter(
              module => module.moduleGroupId.filter(_group => _group._id == group._id).length > 0
            ).sort((a, b) => {
              return (b?.order || 0) - (a?.order || 0)
            })
          }
        })
      })
    }

  })


  const tagImgHelperFn = () => {
    if (availableModules?.length > 0) {
      availableModules && availableModules[mIndex] && availableModules[mIndex].dataSet && availableModules[mIndex].dataSet && availableModules[mIndex].dataSet[0].tag[0] && availableModules[mIndex].dataSet[0].tag[0].imgUrlPortrait ?
        setTagImg({
          loading: false,
          src: availableModules[mIndex].dataSet[0].tag[0].imgUrlPortrait
        }) :
        setTagImg({
          loading: false,
          src: ModuleLogo
        })
    }
    else
      setTagImg({
        loading: true,
        src: LoadingSpinner
      })
  }

  useEffect(() => {
    tagImgHelperFn();
    if (availableModules.length == 1)
      setMswitch(false)
  }, [mIndex])

  return (
    <div className={"dashboard-cntr"}>
      <LeftNav language={i18n.language} />
      <div className='d-flex w-100 h-100'>
        <div className={`h-100 ap-program-leftImg-container  ${!mSwitch ? 'w-27 overflow-hidden' : 'w-100 overFlowYAuto'} tct-scrollable-darkgray`} >
          <div className='d-flex flex-column h-100 w-100' style={{ padding: '1%' }}>
            {!mSwitch ? <div className='ir-left-panel-container'>
              <div>
                <div style={{ fontSize: '1.125rem' }} ref={userNameRef} >
                  {t('WELCOME_SHORT', { name: auth.user.fullname })}
                </div>
                <div className='ir-program-name'>
                  {rel_Modules?.programName}
                  {/* {console.log(availableModules[mIndex]?.programName)} */}
                </div>
                <div>
                  <div className='ir-role-text'>
                    {rel_Modules?.title}
                  </div>
                  {
                    availableModules.length > 1 &&
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: "10px" }} onClick={handleSwitch}>
                      <Icon path={mdiAutorenew}
                        size={1}
                        // rotate={270}
                        color={'#BA1F00'}
                        spin={mSwitchSpin ? 0.5 : 0}
                        style={{ verticalAlign: "middle" }}
                      />
                      <div className='ir-choose-role-text'>{t('CHOOSE_PROGRAM')}</div>
                    </div>
                  }
                </div>
              </div>
              <div className='text-center'>
                {rel_Modules?.programIcon && <img src={rel_Modules.programIcon} style={{ maxWidth: '65%' }} alt='program-img' />}
              </div>
            </div> : ""}
            {
              mSwitch ? <div style={{ display: 'flex', height: "100%" }}>
                <div style={{ margin: '1.5rem 2.5rem', width: "60%" }}>
                  <div style={{ color: '#898989', fontSize: "26px" }}>
                    {t('WELCOME', { name: auth.user.fullname })}
                  </div>

                  <div style={{ margin: '1.25rem 0px 0.75rem', color: '#898989', fontWeight: 'bold', fontSize: "22px" }}>
                    {t('CONDUCT_ROLE')}
                  </div>
                  {availableModules.length > 0 && availableModules.map((module, i) => {
                    // console.log(mIndex);
                    return <Radio key={module.id} label={module.title} mIndex={mIndex} setMindex={setMindex} setMswitch={setMswitch} value={module.id} />
                  })
                  }
                </div>
                {/* <img src={ProgramImg} style={{ marginTop: "auto" }} /> */}
              </div> : ""}
            {/* </div> */}
          </div>
        </div>

        {!mSwitch ? <div className='w-75 h-100 tct-scrollable-darkgray overFlowYAuto' style={{ padding: '0% 7%', backgroundColor: '#F2F2F2' }}>
          {Object.keys(groups).length > 0 ? Object.values(groups).map((g, z) => <>
            <div className='ir-program-count-container'>
              <div className='ir-program-count' style={{ marginLeft: '10px' }}>
                {`Module ${z + 1} : ${g.title} (${g.subModules.length}) `}
              </div>
            </div>
            <div style={{ margin: '10px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '25px 50px' }}>
              {g.subModules.map((s, i) => showModules(s, i, z))}
            </div>
          </>) :
            <div>
              <div className='ir-program-count-container'>
                <div className='ir-program-count' style={{ marginLeft: '10px' }}>
                  {`${rel_Modules?.allModuleHeading || "Program Modules"}  (${rel_Modules?.dataSet?.length || 'Fetching'})`}
                </div>
                {/* {(isIR || isBPUT) && (tagDetail?.showLanguages?.length > 1) && <LanguageSelection
                  selectedLang={selectedLang}
                  show={(isIR || isBPUT) ? true : false}
                  onLanguageChange={(language) => setSelectedLang(language)}
                  langugeList={tagDetail?.showLanguages}
                />} */}
              </div>
              <div style={{ margin: '10px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '25px 50px' }}>
                {(rel_Modules?.dataSet || []).map((el, i) => show1Modules(el, i))}
              </div>
            </div>
          }

          <div className='ir-dashboard-copyright'>
            Copyright 2022; Illumine. All rights reserved.
          </div>
        </div> : ""}
      </div>
    </div >
  )
}
