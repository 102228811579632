import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createSession, getBatchForCoachAndModule, getOTP, storeSelectedBatch, createBatch, getUser } from "../../application/redux_actions"
import CircularProgress from '@material-ui/core/CircularProgress';

import './style.css'
import store from '../../../Redux_Store';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { TextField } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useDispatch, useSelector } from 'react-redux';
import Interaction from '../../application/Interaction';


const useStyles = makeStyles((theme) => ({
    root: {
    },
    paper: {
        width: '45%',
        borderRadius: '24px',
        overflow: 'hidden'
    },
    buttonLabel: {
        fontFamily: 'tablet-gothic !important'
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#e4001b !important',
            color: 'white',
        }
    },
    outlinedSelect: {
        boxShadow: '0px 3px 6px #00000021',
        height: '4.5vh',
        fontSize: '1.2em',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center'
    },
    outlinedSelectIcon: {
        width: '2em',
        height: "2em",
        top: 'unset'
    },
    batchInputRoot: {
        boxShadow: '0px 3px 6px #00000021'
    }
}));


const useStyles_2 = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '90%',
        borderRadius: '24px',
        overflow: 'hidden'
    }
}));

const useStyles_3 = makeStyles((theme) => ({
    paper: {
        width: '40%',
        padding: '2em',
        borderRadius: '24px'
    }
}));

function ConfirmationDialog(props) {

    const handleCancel = () => {
        props.handleCloseConfirm();
    };

    const handleOk = () => {
        props.handleCloseAll();
        // window.location.pathname = "/application"
    };

    const classes_3 = useStyles_3();


    return (
        <Dialog
            aria-labelledby="confirmation-dialog-title"
            open={props.confirmOpen}
            className='ir-tisa-font'
            classes={{
                paper: classes_3.paper
            }}
        >
            <div style={{ fontWeight: 'bold' }}>
                Are you sure you want to cancel?
            </div>
            <div style={{ margin: '1em 0', fontSize: '0.8em' }}>
                This will cancel the session and you will loose the attendance marked.
            </div>

            <div className='d-flex align-items-center justify-content-space-between' style={{ marginTop: '1em' }}>
                <Button autoFocus onClick={handleCancel} className='end-button-dialog-drawer'>
                    DON'T CANCEL
                </Button>
                <Button onClick={handleOk} className='dont-end-button-dialog-drawer'>
                    CANCEL SESSION
                </Button>
            </div>
        </Dialog>
    );
}
const etah = (event) => {
    switch (event.eventName) {
        case "JOIN_SESSION":
            store.dispatch({ type: "STUDENT_JOINED", payload: event.eventData })
            break;

        default:
            console.log(event)
    }
}

const DropDownSelection = ({ label, value, setValue, options, newText, errorText, textValue, setTextValue, textLabel, helperText, verifyButtonText, verifyOnClick }) => {
    const classes = useStyles();

    return <>
        <FormControl variant="outlined" style={{ margin: '1em 0' }} fullWidth>
            <InputLabel id="ir-select-batch-label" style={{ fontWeight: 'bold' }}>{label}</InputLabel>
            <Select
                labelId="ir-select-batch-label"
                id="ir-select-batch-label-outlined"
                value={value}
                label={label}
                classes={{
                    outlined: classes.outlinedSelect,
                    icon: classes.outlinedSelectIcon
                }}

                onChange={(event) => {
                    setValue(event.target.value)
                }}
            >
                {options.map((opt, batchIndex) =>
                    <MenuItem style={{ fontWeight: 'bold', fontSize: '1.25em' }} classes={{ root: 'ir-tisa-font' }}
                        key={`${opt?.value}-${opt.key}`} value={opt?.value} >
                        {opt.key}
                    </MenuItem>)}
                {newText ? <MenuItem style={{ fontWeight: 'bold', fontSize: '1.25em' }} classes={{ root: 'ir-tisa-font' }}
                    value={newText} >
                    {newText}
                </MenuItem> : null}

            </Select>
        </FormControl>
        {options[value]?.key ? null : value === newText ? <div style={verifyButtonText == undefined ? {} : { display: "flex" }}>
            <TextField
                key={label}
                fullWidth={verifyButtonText == undefined}
                label={textLabel}
                helperText={helperText}
                style={{ width: "100%" }}
                variant="outlined"
                InputProps={{
                    classes: {
                        root: classes.batchInputRoot
                    }
                }}
                error={errorText}
                value={textValue}
                onChange={(event) => setTextValue(event.target.value)}
            />
            {verifyButtonText ? <Button
                style={{ backgroundColor: 'rgb(255, 186, 54)', borderRadius: "9px", fontWeight: "bold", marginLeft: "1%" }}
                onClick={verifyOnClick}
            >{verifyButtonText}</Button> : null}
        </div> : null
        }

    </>
}

const SessionList = ({ description, moduleIndex, estimatedTime, batchTextValue, setBatchTextValue, handleCloseInfo, app, moduleId, user_token, open, coach_id, title }) => {
    const [dropDownSelectedBatch, setDropDownSelectedBatch] = useState('');
    const [batchCoTrainer, setBatchCoTrainer] = useState({})
    const [coTrainer, setCoTrainer] = useState('');
    const [errorTextField, setErrorTextField] = useState({ batch: false, coTrainer: false });
    const [coTrainers, setCoTrainers] = useState([])
    // const { createdSession } = useSelector(state => state.app)
    const [selectedBatch,] = useState('');
    // const date = createdSession ? createdSession.createdAt : new Date(createdSession?.createdAt)

    useEffect(() => {
        if (dropDownSelectedBatch && batchCoTrainer?.[dropDownSelectedBatch]?.[0])
            setCoTrainer(batchCoTrainer?.[dropDownSelectedBatch]?.[0])
        else setCoTrainer("")
    }, [dropDownSelectedBatch])

    useEffect(() => {
        open && getBatchForCoachAndModule({ user_token, coachId: coach_id, moduleId }).then((res) => {
            if (res.code) return
            res.map((batch) => setBatchCoTrainer((old) => ({
                ...old,
                [batch._id]: batch?.coachId?.filter(x => String(x) != String(coach_id))
            })))
        })
        open && getUser(user_token, {
            division: app?.user?.meta?.railways?.division,
            zone: app?.user?.meta?.railways?.zone,
            college_code: app?.user?.meta?.bput?.college_code
        }).then((res) => {
            setCoTrainers(res)
        })
    }, [open, title])

    return <>
        <div onClick={handleCloseInfo} className='ap-conduct-dialog-close-session-button'>
            <Icon path={mdiClose}
                size={1.5}
                color='black'
            />
        </div>
        <div style={{ padding: '0em 1.5em 1.5em', overflowY: "auto", maxHeight: "80vh", display: "flex", flexDirection: "column" }}>

            {app.isLoading ? <div className='w-100 h-100 flex-center'>
                <CircularProgress classes={{ root: 'circular-progress-color my-1' }} size={50} />
            </div> : <>
                {/* {DropDownSelection({
                    label: "Select Batch",
                    value: dropDownSelectedBatch,
                    setValue: setDropDownSelectedBatch,
                    options: app.batchDetails.map((batch) => ({ key: batch?.batchName, value: batch?._id })),
                    newText: "New Batch",
                    errorText: errorTextField?.batch,
                    textValue: batchTextValue?.batch,
                    setTextValue: (value) => {
                        setBatchTextValue({ ...batchTextValue, batch: value })
                    },
                    textLabel: "Please enter batch name",
                    helperText: "Format: Division_Program_Location_Batch (e.g. NGP_SM_Ajni_01)"
                })} */}

                <DropDownSelection
                    label="Select Batch"
                    value={dropDownSelectedBatch}
                    setValue={setDropDownSelectedBatch}
                    options={app.batchDetails.map((batch) => ({ key: batch?.batchName, value: batch?._id }))}
                    newText="New Batch"
                    errorText={errorTextField?.batch}
                    textValue={batchTextValue?.batch}
                    setTextValue={(value) => {
                        setBatchTextValue({ ...batchTextValue, batch: value })
                    }}
                    textLabel={"Please enter batch name"}
                    helperText={"Format: Division_Program_Location_Batch (e.g. NGP_SM_Ajni_01)"}
                />

                <DropDownSelection
                    label="Select Co-trainer"
                    value={coTrainer}
                    setValue={setCoTrainer}
                    options={[{ key: "Conducting session alone", value: 'Running Alone' }]
                        .concat(coTrainers?.map((train) => ({ key: train.fullname, value: train?.id })) || [])
                    }
                    newText="Add Co-trainer"
                    errorText={errorTextField?.coTrainer}
                    textValue={batchTextValue?.coTrainer}
                    setTextValue={(value) => setBatchTextValue({ ...batchTextValue, coTrainer: value })}
                    textLabel={"Please enter co-trainer Mobile Number"}
                    verifyButtonText="Verify"
                    verifyOnClick={async () => {
                        await getUser(user_token, { phone: batchTextValue.coTrainer }).then((res) => {
                            if (res.length > 0) {
                                setCoTrainers(oldTrainers => [...oldTrainers, res?.[0]])
                                setCoTrainer(res?.[0].id)
                                setBatchTextValue(oldBatchTextValue => ({ ...oldBatchTextValue, coTrainer: "" }))
                            }
                            else alert("There is no master trainer registered with this number")
                        })
                    }}
                // helperText={"Format: Division_Program_Location_Batch (e.g. NGP_SM_Ajni_01)"}
                />
            </>
            }

            <div className='ir-session-details-container'>
                <div className='font-weight-bold'>Session details:</div>
                <div className='d-flex justify-content-space-between'>
                    <div style={{ width: '43%' }}>
                        {/* <div style={{ fontSize: '0.75em' }}>
                            Module {moduleIndex < 10 ? ('0' + (moduleIndex + 1)) : moduleIndex + 1}
                        </div> */}
                        <div style={{ fontSize: '0.75em', fontWeight: 'bold' }}>
                            {description}
                        </div>
                    </div>
                    <div style={{ fontSize: '0.75em' }}>
                        <div>Date: {moment().format('DD/MM/YYYY')}</div>
                        <div>Start Time: {moment().format('LT')} </div>
                        {estimatedTime && <div>Estimated Duration: {estimatedTime?.hours + ' hr ' + estimatedTime?.minutes + ' mins'}</div>}
                    </div>
                </div>
            </div>
            <Link to={`/application/conduct/${moduleId}`} style={{ cursor: (dropDownSelectedBatch == '') || (coTrainer == "") ? "no-drop" : "pointer" }} onClick={async (event) => {
                let newBatch = '';
                if (coTrainer == "") {
                    setErrorTextField((oldText) => ({ ...oldText, coTrainer: true }));
                    event.preventDefault();
                    return;
                }
                if (dropDownSelectedBatch === 'New Batch' && batchTextValue?.batch) {
                    await createBatch(user_token, { batchName: batchTextValue?.batch, coachId: (coTrainer != "Running Alone" ? [coach_id, coTrainer] : [coach_id]) }).then(res => {
                        if (res?.code) {
                            alert(res?.message)
                            return;
                        }

                        if (res?._id)
                            newBatch = res;
                    })
                    setErrorTextField((oldText) => ({ ...oldText, batch: false }));
                    // setErrorTextField(false);
                }
                else if (dropDownSelectedBatch === 'New Batch' && !batchTextValue?.batch) {
                    setErrorTextField((oldText) => ({ ...oldText, batch: true }));
                    // setErrorTextField(true);
                    event.preventDefault();
                }

                if ((dropDownSelectedBatch === 'New Batch' && batchTextValue?.batch) || dropDownSelectedBatch) {
                    createSession({ user_token, moduleId, coach_id: coach_id, facilitators: (coTrainer != "Running Alone" ? [coach_id, coTrainer] : [coach_id]), sessionType: 'pre', sessionName: new Date(), batchId: (dropDownSelectedBatch && dropDownSelectedBatch !== 'New Batch') ? dropDownSelectedBatch : newBatch._id }, async (result) => {
                        await getOTP({ user_token, sessionId: result.id, testType: 'pre', coachId: coach_id }).then((res) => {
                            // if (res.code) {
                            //     window.alert("Session is not started properly. Please try again")
                            //     event.preventDefault();
                            // }

                        }).catch(error => {
                            // window.alert("Session is not started properly. Please try again")
                        });
                        // await Interaction.startSession(result.id, etah);
                    })
                    storeSelectedBatch(selectedBatch);
                    // Interaction.startSession(app.createdSession.id, etah);
                }
                else
                    event.preventDefault();

            }}>
                <Button style={{ backgroundColor: '#ffba36', borderRadius: '9px', padding: '0.5', fontWeight: 'bold', fontSize: '0.9em', opacity: (dropDownSelectedBatch !== '') && (coTrainer !== "") ? 1 : 0.5 }} fullWidth
                    disabled={(dropDownSelectedBatch == '') || (coTrainer == "")}>
                    CONDUCT
                </Button>
            </Link>
        </div>
    </>
}

function InfoDialog({ open, index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app, testType, handleCloseInfo, estimatedTime }) {

    const classes = useStyles();
    const classes_2 = useStyles_2();

    const [confirmOpen, setConfirmOpen] = useState(false);

    const [displayStudent, setDisplayStudent] = useState(false);
    const [batchTextValue, setBatchTextValue] = useState({ batch: '', coTrainer: '' });

    // const [batchTextValue, setBatchTextValue] = useState('');

    const handleCloseAll = () => {
        setDisplayStudent(false);
        setConfirmOpen(false);
        handleCloseInfo(false);
    };


    const handleCloseConfirm = () => {
        setConfirmOpen(false);
    };

    return (
        <Dialog
            aria-labelledby="info-dialog-title"
            open={open}
            maxWidth={false}
            classes={{
                paper: displayStudent ? classes_2.paper : classes.paper,
            }}

            className='ir-tisa-font'
        >
            <div className='d-flex w-100 h-100'>
                <div className='w-100' style={{ padding: !displayStudent ? '1.5em' : '2em' }}>
                    <SessionList
                        description={description}
                        moduleIndex={index}
                        estimatedTime={estimatedTime}
                        batchTextValue={batchTextValue}
                        setBatchTextValue={setBatchTextValue}
                        handleCloseInfo={handleCloseInfo}
                        app={app}
                        moduleId={moduleId}
                        user_token={user_token}
                        open={open}
                        coach_id={coach_id}
                        title={title}
                    />
                </div>
            </div>

            <ConfirmationDialog
                id="confirm-id"
                confirmOpen={confirmOpen}
                open={open}
                handleCloseAll={handleCloseAll}
                handleCloseConfirm={handleCloseConfirm}
            />

        </Dialog >
    );
}



export default function ConductDialog({ index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app, buttonTextComponent, buttonClassName, rootClassName, estimatedTime }) {

    const [open, setOpen] = useState(window.location.pathname == `/application/otp/${moduleId}` ? true : false)
    const handleCloseInfo = () => {
        setOpen(false);
    };

    return (
        <div className={rootClassName}>
            <div className={buttonClassName} onClick={() => setOpen(true)}>
                {buttonTextComponent}
            </div>

            <InfoDialog
                id="Info-id"
                moduleId={moduleId}
                title={title}
                description={description}
                index={index}
                mainFilePath={mainFilePath}
                user_token={user_token}
                coach_id={coach_id}
                auth={auth}
                app={app}
                keepMounted
                open={open}
                testType="pre"
                handleCloseInfo={handleCloseInfo}
                estimatedTime={estimatedTime}
            />
        </div >
    );
}
