import React from "react";
// import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
// import { Children } from "react";

import "./auth.layout.scss";

function AuthLayout(props) {
  const { children } = props;
  return (
    <React.Fragment>
      <div className="auth_layout_container">
        <Grid container>
          <Grid item lg={4} md={4}>
            <div className="left_section">
              <div className="top">
                <div className="top_text_wrapper">
                  <div className="sub_head">Future Ready <br /> Contributor Program</div>
                  <div className="head">Coaching<br />Platform</div>
                </div>
                <div className="university_info">
                  <div className="univ_logo_wrapper">
                    <img src="https://www.bput.ac.in/images/bput-logo.png" />
                  </div>
                  <div className="univ_name">
                    For Biju Patanaik <br /> University of Technology
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="illumine_logo">
                  <img src="/illumine_logo.svg" />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={8} md={8}>
            <div className="right_section">{children}</div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

AuthLayout.propTypes = {};

export default AuthLayout;
