import React, { useEffect } from "react"
import { IFRAME_URL } from "../../utilities/common"
import { ReactComponent as FullScreenPage } from './full_screen.svg';

export default ({
    sessionId,
    toolId,
    item,
    isFullScreen,
    currentScene,
    currentUnit,
    app,
    auth,
}) => {
    let _data = {
        sessionId,
        isFullScreen,
        toolId,
        learningToolId: currentUnit,
        currentScene,
        engagementId: item?.engagement,
        otp: app?.otpData?.otp,
        userId: auth.user?.id,
        token: auth?.user_token
    }
    useEffect(() => {
        window.addEventListener("message", (e) => {
            let iframe = document.getElementById(`externalTool-${sessionId}-${item?.engagement}`)
            iframe && iframe.contentWindow && iframe.contentWindow.postMessage(JSON.stringify(_data), "*")
        })

        return () => {
            window.removeEventListener("message", () => { })
        }
    }, [IFRAME_URL, sessionId, isFullScreen])

    let src = `http://localhost:3001/ideaBoard/${sessionId}?toolId=${toolId}&learningToolId=${currentUnit}&currentScene=1&engagementId=${item?.engagement}&otp=${app?.otpData?.otp}&userId=${auth?.user?.id}&token=${auth?.user_token}`

    console.log("src", src)
    return <>
        {isFullScreen ? <iframe
            id={`externalTool-${sessionId}-${item?.engagement}`}
            src={`${IFRAME_URL}/ideaBoard/${sessionId}`}
            // src={src}

            frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"
        /> : <FullScreenPage style={{ width: '100%', height: '100%' }} />}
    </>
}