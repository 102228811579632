import React, { Component } from "react";
import MainRoute from "./Route"
import Config from "../../config"
//ACP Links
import APRoute from "../asianPaints/AuthRoute"
import IRRoute from "../IndianRailways/AuthRoute"
import { isAP, isBPUT, isIR } from "../../utilities/common";

let Application = class Application extends Component {
  render() {
    if (isAP) {
      return <APRoute />
    }
    if (isIR || isBPUT) {
      return <div className='ir-tisa-font w-100 h-100'>
        <IRRoute />
      </div>
    }
    else {
      return <MainRoute />
    }
  }
};

export default Application;
