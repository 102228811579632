import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { createSession, getBatchForCoachAndModule, getBatchStudents, getOTP, markBatchAttendance, resumeSession, startPostSession, storeSelectedBatch } from "../../application/redux_actions"
// import { createSession, getAttendence, getOTP } from "../../application/redux_actions"
import CircularProgress from '@material-ui/core/CircularProgress';
import { CopyToClipboard } from "react-copy-to-clipboard";
// import OtpInput from "react-otp-input";

import './style.css'
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config';
import Interaction from '../../application/Interaction';
import store from '../../../Redux_Store';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleOutlineTwoToneIcon from '@material-ui/icons/CheckCircleOutlineTwoTone';

import Tooltip from '@material-ui/core/Tooltip';

import SearchTextField from './searchField'
import StudentNameList from './studentNameList';



import PlayIcon from './assets/PlayCircle.svg'
import ResumeIcon from './assets/Resume.svg'
import DeleteSession from './assets/DeleteSession.svg'
import { Input } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        // maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '90%',
        height: '90%',
        borderRadius: '24px',
        overflow: 'hidden'
    },
    buttonLabel: {
        fontFamily: 'tablet-gothic !important'
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#e4001b !important',
            color: 'white',
        }
    }
}));


function ConfirmationDialog(props) {

    const handleCancel = () => {
        props.handleCloseConfirm();
    };

    const handleOk = () => {
        props.handleCloseConfirm();
        props.handleCloseInfo();
        // window.location.pathname = "/application"
    };


    return (
        <Dialog
            aria-labelledby="confirmation-dialog-title"
            open={props.confirmOpen}
        >
            <DialogTitle id="confirmation-dialog-title">Are you sure?</DialogTitle>
           
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    No
                </Button>
                <Button onClick={handleOk} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
const etah = (event) => {
    // console.log(event)
    switch (event.eventName) {
        case "JOIN_SESSION":
            store.dispatch({ type: "STUDENT_JOINED", payload: event.eventData })
            break;

        default:
            console.log(event)
    }
}

const DialogLeftSide = ({ description, sessionid, date, duration, mainFilePath }) => {
    return <div className='d-flex flex-column h-100 w-30 justify-content-center' style={{ padding: '2rem' }}>
        <div>
            <div className='ap-conduct-popup-title'>
                {description}
            </div>
            <div className='ap-conduct-popup-session-id-text mt-2' style={{ padding: '0' }}>
                <div>
                    EXPECTED DURATION :  {duration?.hours ? duration?.hours + ' hr ' : ''}
                    {duration?.minutes ? duration.minutes != '0' ? duration?.minutes + " mins" : "" : ''}
                </div>
                <div>
                    DATE : {moment(date).format('DD/MM/YYYY')} | START TIME: {moment(date).format('LT')}
                </div>
            </div>
        </div>
        <div style={{ position: 'relative', width: '100%', height: '50%' }}>
            <img src={mainFilePath} className='w-100 ap-program-leftImg' style={{ paddingLeft: '0px' }} alt='ModuleLogo' />
        </div>
    </div>
}


function InfoDialog({ open, index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app, testType, handleCloseInfo }) {

    const classes = useStyles();

    const [confirmOpen, setConfirmOpen] = useState(false);
    const { createdSession, otpData, studentJoinedList } = useSelector(state => state.app)

    const date = createdSession && createdSession.createdAt && new Date(createdSession?.createdAt)


    const [selectedBatch, setSelectedBatch] = useState('');
    const [displayStudent, setDisplayStudent] = useState(false);


    useEffect(() => {
        open && getBatchForCoachAndModule({ user_token, coachId: coach_id, moduleId })
    }, [open, title])
    

    const handleOpenConfirm = () => {
        setConfirmOpen(true);
        
    };

    const handleCloseConfirm = () => {
        setDisplayStudent(false);
        setConfirmOpen(false);
        handleCloseInfo(false);
    };

    const handleNewBatchConduct = (sessionId, batch) => {

        if (sessionId)
            startPostSession({ user_token, sessionId })
        setDisplayStudent(true);
        setSelectedBatch(batch);
        getBatchStudents({ user_token, batchId: batch._id })
    }





    const StartSessionBox = ({ resume, batch }) => {
        return <div key={batch.batchName + 'S'} className="ap-conduct-dialog-session-box br-20px batch">
            <div className='tablet-gothic'>
                <div className="ap-conduct-dialog-batch-name">{batch.batchName}</div>
                <div className='ap-conduct-popup-session-id-text mt-1' >
                    
                    {!resume ? 'Yet to be conducted' : <>{moment(resume.createdAt).format('DD/MM/YYYY')}  |  {moment(resume.createdAt).format('LT')}
                        <div className="ap-conduct-dialog-delete-session" onClick={() => handleNewBatchConduct(resume.id, batch)}>
                            <img src={DeleteSession} /> &nbsp; DELETE SESSION AND RESTART NEWLY
                        </div>
                    </>}
                </div>
            </div>
           

            {resume ?
                <Link to={`/application/conduct/${moduleId}`} onClick={() => {
                    resumeSession(resume);
                    storeSelectedBatch(batch);
                }}>
                    <Button className="resume"><img src={ResumeIcon} /> &nbsp; Resume </Button>
                </Link>
                : <Button className="conduct" onClick={() => handleNewBatchConduct(false, batch)}><img src={PlayIcon} /> &nbsp; Conduct</Button>}

        </div >
    }

    const SessionList = () => {
        return <>
            <div className='d-flex flex-column h-100'>
                <div onClick={handleOpenConfirm} className='ap-conduct-dialog-close-session-button'>
                    Close
                </div>
                <div className='ap-conduct-myapp-text' style={{ color: "#828080" }}>
                    Conduct a session for your batches
                </div>
                <div className='h-85 overFlowYAuto tct-scrollable-darkgray' >
                    {
                        app.batchDetails.length > 0 ? app.batchDetails?.map(batch => <StartSessionBox key={batch.batchName} resume={batch.resume} batch={batch} />) : <div className='w-100 h-100 flex-center'>
                            <CircularProgress classes={{ root: 'circular-progress-color' }} size={70} />
                        </div>
                    }
                </div>
               
            </div>
        </>
    }


    return (
        <Dialog
            aria-labelledby="info-dialog-title"
            open={open}
            maxWidth={false}
            classes={{
                paper: classes.paper,
            }}
        >
            <div className='d-flex h-100'>
                <DialogLeftSide
                    description={description}
                    sessionid={createdSession?.sessionid}
                    date={date}
                    duration={{
                        hours: app && app.moduleDetails && app.moduleDetails[moduleId].estimatedTime && app.moduleDetails[moduleId].estimatedTime.hours,
                        minutes: app && app.moduleDetails && app.moduleDetails[moduleId].estimatedTime && app.moduleDetails[moduleId].estimatedTime.minutes
                    }}
                    mainFilePath={mainFilePath}
                />
                <div className='w-70 ap-gray-background-2' style={{ padding: '1.5rem 2.5rem' }}>

                    {!displayStudent ? <SessionList /> : <StudentNameList app={app} handleOpenConfirm={handleOpenConfirm} moduleId={moduleId} user_token={user_token} coach_id={coach_id} selectedBatch={selectedBatch} />}
                </div>
            </div>

            <ConfirmationDialog
                classes={{
                    paper: classes.paper,
                }}
                id="confirm-id"
                keepMounted
                confirmOpen={confirmOpen}
                open={open}
                handleCloseInfo={handleCloseInfo}
                handleCloseConfirm={handleCloseConfirm}
            />

        </Dialog >
    );
}



export default function ConductDialog({ index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app, buttonTextComponent, buttonClassName, rootClassName }) {

    const [open, setOpen] = useState(window.location.pathname == `/application/otp/${moduleId}` ? true : false)
    const handleCloseInfo = () => {
        setOpen(false);
        // window.location.pathname = `/application`
    };

    return (
        <div className={rootClassName}>
            <div className={buttonClassName} onClick={() => setOpen(true)}>
                {buttonTextComponent}
            </div>

            <InfoDialog
                id="Info-id"
                moduleId={moduleId}
                title={title}
                description={description}
                mainFilePath={mainFilePath}
                user_token={user_token}
                coach_id={coach_id}
                auth={auth}
                app={app}
                keepMounted
                open={open}
                // setOpen={setOpen}
                testType="pre"
                handleCloseInfo={handleCloseInfo}
            />
        </div >
    );
}
