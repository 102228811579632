import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mdiAlert, mdiCheckDecagram } from '@mdi/js';

const useStyles_3 = makeStyles((theme) => ({
    paper: {
        width: '40%',
        padding: '1.5em 3em',
        borderRadius: '24px'
    }
}));


const useStyles_2 = makeStyles((theme) => ({
    paper: {
        width: '40%',
        padding: '2em',
        borderRadius: '24px'
    }
}));

export default ({ parentId, open, handlePopup }) => {

    const classes_3 = useStyles_3();
    const app = useSelector(state => state.app);

    const [message, setMessage] = useState({
        title: '',
        description: '',
        icon: ''
    })

    useEffect(() => {
        let casePercentage = (Object.keys(app.assessmentDoneStudent).length / Object.keys(app.studentJoinedList).length) * 100;

        if (casePercentage === 100)
            setMessage({ title: 'Congratulations!', description: 'You have successfully completed the Session.', icon: mdiCheckDecagram })
        else if (casePercentage >= 80 && casePercentage < 99)
            setMessage({ title: 'Congratulations!', description: 'Most of the participants have completed assessments. Please ensure the others also finish soon.', icon: mdiCheckDecagram })
        else if (casePercentage >= 60 && casePercentage < 79)
            setMessage({ title: 'Almost there!', description: 'Some participants are yet to complete the assessments. Please ensure that they complete them.', icon: mdiAlert })
        else if (casePercentage >= 40 && casePercentage < 59)
            setMessage({ title: 'Ohho!', description: 'Many participants have not completed. Please ensure they finish, and contact support if needed.', icon: mdiAlert })
        else
            setMessage({ title: 'Oops!', description: 'Many have not completed the assessments. If you faced any technical issue, contact support.', icon: mdiAlert })


    }, [open])

    // return (
    //     <div>
    //         <Dialog
    //             aria-labelledby="confirmation-dialog-title"
    //             open={open}
    //             className='ir-tisa-font'
    //             classes={{
    //                 paper: classes_3.paper
    //             }}
    //             container={() => document.getElementById(parentId)}
    //         >
    //             <div className='text-center font-weight-bold'>

    //                 <Icon path={message.icon}
    //                     size={2.5}
    //                     // rotate={270}
    //                     color={message.icon === mdiAlert ? '#BA1F00' : '#1FB500'}
    //                     style={{ verticalAlign: "middle" }}
    //                 />
    //                 <div>{message.title}</div>
    //             </div>
    //             <div style={{ margin: '1em 0', fontSize: '0.8em' }}>
    //                 {message.description}
    //             </div>

    //             <div>
    //                 <div className='d-flex w-100'>
    //                     <Link to='/application' className='ir-end-session-button' onClick={() => handlePopup(false)}>
    //                         CONFIRM
    //                     </Link>
    //                 </div>
    //                 <Button fullWidth className='dont-end-button-dialog-drawer' onClick={() => handlePopup(false)}>
    //                     CANCEL
    //                 </Button>
    //             </div>
    //         </Dialog>
    //     </div>
    // )

    return <Dialog
        open={open}
        
        keepMounted
        classes={{
            paper: useStyles_2().paper
        }}
        className='ir-tisa-font'
        disableBackdropClick
        disableEscapeKeyDown
        container={() => document.getElementById(parentId)}
    >
        <div style={{ fontWeight: 'bold' }}>
            Do you want to close this session?
        </div>
        <div style={{ margin: '1em 0', fontSize: '0.8em' }}>
            {/* You can resume it later by pressing conduct button on the current module. */}
        </div>
        <div className='d-flex align-items-center justify-content-space-between' style={{ marginTop: '1em' }}>
            <Button className='end-button-dialog-drawer' onClick={() => handlePopup(false, false)} autoFocus>
                DON'T END
            </Button>
            <Link to='/application' className='dont-end-button-dialog-drawer' onClick={() => handlePopup(false, true)} >
                YES, END IT
            </Link>
        </div>
    </Dialog>
}
