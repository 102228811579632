import React, { useEffect, useRef, useState } from "react";
import Frame from "./frame";
import { IconButton, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Navigation, { fnupdatePercentage, updUnit } from './navigationNew';
import DrawerItem from './drawer';
import { isBPUT, isIR } from '../../utilities/common';
import useFullscreenStatus from './useFullscreenStatus';
import EndSessionPopup from './EndSessionPopup';
import { convertFromRaw } from 'draft-js';
import { addFormValues, endSession } from '../../containers/application/redux_actions';
import LanguageSelection from '../LanguageSelection';
import { useSelector } from "react-redux";

let LinearNavigator = (props) => {
  const {
    outerHeight: ScreenHeight,
    outerWidth: ScreenWidth,
  } = window;
  const scenario = useSelector((state) => state.app.scenario);

  const { auth, app, classes } = props;
  let {
    units,
    moduleData,
    slideWidth,
    slideHeight,
    currentScene,
    currentUnit,
    roadmaps,
    currentRoadmap,
    updatecurrentUnit,
    setcurrentScene,
    user_uid,
    user_token,
    drawerVariant,
    margin,
    typPC,
    availableModules,
    setCurrentRoadmap,
    selectedLang,
    setSelectedLang,
  } = props;
  let [isLoaded, updateLoadStatus] = useState(false);
  let [isDrawerCalled, updateDrawerCalled] = useState(false);
  let isFullScreen, toggleFullScreen;
  let slides =
    units && units[currentUnit] && units[currentUnit].activeContent
      ? units[currentUnit].activeContent
      : {};
  let percomp = "0";
  let [percomps, updatepercomps] = useState(0);
  const frameRef = useRef(null);
  const [nextButton, setNextButton] = useState({
    text: "next",
    css: "",
  });
  const [prevButton, setPrevButton] = useState({
    text: "prev",
    css: "",
  });
  let aContent =
    units &&
      units[currentUnit] &&
      units[currentUnit].activeContent &&
      units[currentUnit].activeContent[currentScene]
      ? units[currentUnit].activeContent[currentScene].id
      : "";

  const lastScene = slides.length - 1;

  const [endSessionPopup, setEndSessionPopup] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const tagDetail = app?.selectedRole && app?.availableTags?.filter(tag => tag.id == app?.selectedRole)?.[0]

  try {
    [isFullScreen, toggleFullScreen] = useFullscreenStatus(frameRef);
  } catch (e) {
    isFullScreen = false;
    toggleFullScreen = undefined;
  }

  useEffect(() => {
    updateLoadStatus(true);
  }, [isLoaded]);

  const keyDownHandler = (event) => {
    switch (event.code) {
      case "ArrowLeft":
        navigationEvent("previous");
        break;
      case "ArrowRight":
        navigationEvent("next");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const learningTool =
      roadmaps?.[currentRoadmap]?.LearningTools &&
      Object.keys(roadmaps?.[currentRoadmap]?.LearningTools);
    if (learningTool) {
      let isLastLearningTool =
        learningTool[learningTool.length - 1] == currentUnit;
      if (isLastLearningTool && currentScene === 0 && lastScene === 0)
        setNextButton({
          text: "end session",
          css: "ir-bottom-nav-button-close",
        });
      else if (!isLastLearningTool && currentScene == lastScene)
        setNextButton({
          text: "next unit",
          css: "ir-bottom-nav-button-next",
        });
      else if (currentScene < lastScene) {
        setNextButton({
          text: "next",
          css: "",
        });
      }
      if (
        currentScene === 0 &&
        Object.keys(roadmaps[currentRoadmap].LearningTools).indexOf(currentUnit)
      ) {
        setPrevButton({ text: "prev unit", css: "" });
      } else setPrevButton({ text: "prev", css: "" });
    }
  }, [currentUnit, currentScene]);

  const navigationEvent = (event) => {
    // console.log("units", units?.[currentUnit]);
    // console.log("currentUnit", currentUnit);
    // console.log("currentScene", currentScene);
    const currentSlide =
      units?.[currentUnit]?.activeContent?.[currentScene] | null;

    const toolDataSave = () => {
      let sessionId = app.createdSession.id;
      let user_token = auth.user_token;
      let fieldData = convertEditorTypeData();
      addFormValues({ sessionId, user_token, fieldData });
    };

    const convertEditorTypeData = () => {
      let convertedData = {};
      Object.keys(app.engagementData).map((textBox, i) => {
        convertedData[textBox] = app.engagementData[textBox].blocks
          ? convertFromRaw(app.engagementData[textBox]).getPlainText()
          : app.engagementData[textBox];
      });
      return convertedData;
    };

    const handlelastSlide = () => {
      if (
        roadmaps[currentRoadmap].LearningTools[currentUnit].order >=
        Object.keys(roadmaps[currentRoadmap].LearningTools).length
      )
        setNextButton({
          text: "end session",
          css: "ir-bottom-nav-button-close",
        });
      else
        setNextButton({
          text: "next unit",
          css: "ir-bottom-nav-button-next",
        });
    };

    const lastScene = slides.length - 1;

    const data = moduleData;
    const moduleId = data.id;

    const learningTools = Object.keys(roadmaps[currentRoadmap].LearningTools);

    switch (event) {
      case "previous":
        typPC !== "Prepare" &&
          currentSlide.type === "tool" &&
          app.engagementData &&
          Object.keys(app.engagementData).length !== 0 &&
          toolDataSave();
        const currentLearningToolIndex = learningTools.indexOf(currentUnit);

        if (currentScene > 0) {
          setPrevButton({ text: "prev", css: "" });
          setcurrentScene(currentScene - 1);
        } else if (currentScene === 0 && currentLearningToolIndex) {
          let m = currentLearningToolIndex - 1;
          // console.log("currentLearningToolIndex", currentLearningToolIndex)
          // console.log("Previous", units[learningTools?.[currentLearningToolIndex]]?.activeContent[m]?.scenarioId)
          setPrevButton({ text: "prev unit", css: "" });

          // while (
          //   Object.values(scenario).length > 0 &&
          //   units[learningTools?.[currentLearningToolIndex]]?.activeContent[m]?.scenarioId &&
          //   m != units[learningTools?.[currentLearningToolIndex]]?.activeContent?.length &&
          //   !Object.values?.(scenario).includes(units[learningTools?.[currentLearningToolIndex]]?.activeContent)
          // ) { m++ }
          // // console.log("scenario", Object.values(scenario))
          // console.log(Object.values?.(scenario).includes(units[learningTools?.[currentLearningToolIndex - 1]]?.activeContent[m]?.scenarioId), !Object.values(scenario).length > 0, units[learningTools?.[currentLearningToolIndex - 1]]?.activeContent[0]?.scenarioId)

          // if (!Object.values(scenario).length > 0 || Object.values?.(scenario).includes(units[learningTools?.[currentLearningToolIndex - 1]]?.activeContent[m]?.scenarioId) || !units[learningTools?.[currentLearningToolIndex - 1]]?.activeContent[0]?.scenarioId) {
          //   setcurrentScene(0);
          //   console.log(learningTools)
          //   // updatecurrentUnit();

          // }

          // console.log("Hello", currentUnit, currentLearningToolIndex, learningTools[currentLearningToolIndex - 1])
          updatecurrentUnit(learningTools[currentLearningToolIndex - 1]);
          updUnit(
            roadmaps,
            currentRoadmap,
            currentUnit,
            updatecurrentUnit,
            setcurrentScene,
            "Prev"
          );
        } else if (currentScene === lastScene) {
        } else if (currentScene !== lastScene - 1)
          setNextButton({ text: "next", css: "" });

        break;
      case "next":
        typPC !== "Prepare" &&
          currentSlide.type === "tool" &&
          app.engagementData &&
          Object.keys(app.engagementData).length !== 0 &&
          toolDataSave();

        let currentLearningToolIndex1 = learningTools.indexOf(currentUnit);
        if (currentScene < lastScene) {
          let nextSlide = currentScene + 1;
          while (
            units?.[learningTools?.[currentLearningToolIndex1]]?.activeContent?.[nextSlide]?.scenarioId &&
            Object.keys(scenario || {}).length > 0 &&
            !Object.values(scenario)?.
              reduce((total, value) => total + value, [])?.
              includes(units[learningTools?.[currentLearningToolIndex1]]?.activeContent?.[nextSlide]?.scenarioId)
          ) {
            nextSlide++;
            if (units?.[learningTools?.[currentLearningToolIndex1]]?.activeContent?.length == nextSlide) {
              // console.log("asdasd", units?.[learningTools?.[currentLearningToolIndex1]]?.activeContent?.length, nextSlide, currentLearningToolIndex1,)
              currentLearningToolIndex1++;
              // console.log("asdasd", units?.[learningTools?.[currentLearningToolIndex1]]?.activeContent?.length, nextSlide, currentLearningToolIndex1++,)
              nextSlide = 0;
            }
          }

          updatecurrentUnit(learningTools[currentLearningToolIndex1]);
          setcurrentScene(nextSlide);
          setNextButton({
            text: "next",
            css: "",
          });
          setPrevButton({
            text: "prev",
            css: "",
          });

          // moduleId = data.id;
          let rrid = currentRoadmap;
          let isLastSlide = "0";
          const lid = currentUnit;
          let cScne = currentScene + 1;

          if (cScne === lastScene) {
            isLastSlide = "1";
          }

          if (currentScene == lastScene - 1) handlelastSlide();

          let sessionId = typPC == "Conduct" && app?.createdSession?.id;
          fnupdatePercentage({
            uid: user_uid,
            moduleId,
            typPC,
            cScne,
            rrid,
            lid,
            token: user_token,
            aContent,
            updatepercomps,
            isLastSlide,
            sessionId,
          });
        } else if (currentScene == lastScene) {
          // setCurrentRoadmap(nextRoadmap)

          let nextSlide = currentScene + 1
          updUnit(
            scenario,
            units,
            roadmaps,
            currentRoadmap,
            currentUnit,
            updatecurrentUnit,
            setcurrentScene,
            "Next"
          );
          if (nextButton.text !== "end session")
            setNextButton({
              text: "next",
              css: "",
            });
          else if (nextButton.text === "end session") {
            if (typPC === "Prepare") setRedirect(true);
            else setEndSessionPopup(true);
          }
          // setcurrentScene(0)
        }
        break;
      default:
        break;
    }
  };

  function layout({ w, h }) {
    return (
      <React.Fragment>
        <div
          id="frame-id"
          ref={frameRef}
          tabIndex={0}
          onKeyDown={keyDownHandler}
        >
          {isFullScreen ? (
            <DrawerItem
              isLoaded={isLoaded}
              data={moduleData}
              units={units}
              isDrawerCalled={isDrawerCalled}
              updateDrawerCalled={updateDrawerCalled}
              width={slideWidth}
              roadmaps={roadmaps}
              currentUnit={currentUnit}
              currentRoadmap={currentRoadmap}
              updatecurrentUnit={updatecurrentUnit}
              setcurrentScene={setcurrentScene}
              uid={user_uid}
              token={user_token}
              drawerVariant={drawerVariant}
              typPC={typPC}
              availableModules={availableModules}
              percomps={percomps}
              setCurrentRoadmap={setCurrentRoadmap}
              isFullScreen={isFullScreen}
            />
          ) : null}

          {slides.length ? (
            <Frame
              {...props}
              slides={slides}
              currentScene={currentScene}
              currentUnit={currentUnit}
              slideWidth={w}
              slideHeight={h}
              isFullScreen={isFullScreen}
              typPC={typPC}
              language={selectedLang}
            />
          ) : (
            <div style={{ width: w, height: h, backgroundColor: "#eaeaea" }} />
          )}
          <Navigation
            selectedLang={selectedLang}
            isFullScreen={isFullScreen}
            show={(isIR || isBPUT) && (slides?.[currentScene]?.multiLang) ? true : false}
            onLanguageChange={(language) => setSelectedLang(language)}
            langugeList={tagDetail?.showLanguages || Object.keys(slides?.[currentScene]?.url || {})}
            sessionId={app?.createdSession?.id || app?.createdSession?._id}
            setcurrentScene={setcurrentScene}
            currentScene={currentScene}
            firstScene={slides[0]}
            lastScene={slides.length - 1}
            currentUnit={currentUnit}
            updatecurrentUnit={updatecurrentUnit}
            units={units}
            currentRoadmap={currentRoadmap}
            uid={user_uid}
            token={user_token}
            roadmaps={roadmaps}
            typPC={typPC}
            data={moduleData}
            percomps={percomps}
            updatepercomps={updatepercomps}
            toggleFullScreen={toggleFullScreen}
            setCurrentRoadmap={setCurrentRoadmap}
            setEndSessionPopup={setEndSessionPopup}
            navigationEvent={navigationEvent}
            nextButton={nextButton}
            prevButton={prevButton}
            redirect={redirect}
            setRedirect={setRedirect}
          />
          {endSessionPopup && (
            <EndSessionPopup
              parentId="frame-id"
              open={endSessionPopup}
              handlePopup={(prop, end) => {
                setEndSessionPopup(prop);
                if (end) {
                  // startPostSession({ user_token, sessionId: app?.createdSession?.id })
                  endSession({
                    user_token,
                    sessionId: app?.createdSession?.id,
                  });
                }
              }}
            />
          )}
        </div>
      </React.Fragment>
    );
  }

  let w, h;
  let aw = ScreenWidth - 84;
  let ah = ScreenHeight - 48;
  let r = aw / ah;

  if (r < 16 / 9) {
    //Width will hit first;
    w = aw;
    h = (aw * 9) / 16;
  } else {
    //height will hit first;
    h = ah;
    w = (ah * 16) / 9;
  }

  return (
    <div id="dvouter" className="h-100 d-flex">
      <DrawerItem
        isLoaded={isLoaded}
        data={moduleData}
        units={units}
        isDrawerCalled={isDrawerCalled}
        updateDrawerCalled={updateDrawerCalled}
        width={slideWidth}
        roadmaps={roadmaps}
        currentUnit={currentUnit}
        currentRoadmap={currentRoadmap}
        updatecurrentUnit={updatecurrentUnit}
        setcurrentScene={setcurrentScene}
        uid={user_uid}
        token={user_token}
        drawerVariant={drawerVariant}
        typPC={typPC}
        availableModules={availableModules}
        percomps={percomps}
        setCurrentRoadmap={setCurrentRoadmap}
      />
      <div
        id="linear-navigator"
        style={{
          width: slideWidth,
          height: "100%",
          margin: typPC === "Prepare" ? "0" : "auto",
        }}
        className={"ir-conduct-linear-navigator"}
      >
        {drawerVariant === "temporary" ? (
          <div className="ir-prepare-drawer-postion">
            <IconButton
              onClick={() => {
                updateDrawerCalled(!isDrawerCalled);
              }}
              style={{
                width: 42,
                height: 42,
                borderRadius: 21,
                backgroundColor: "#000",
                color: "#FFF",
              }}
            >
              <MenuIcon />
            </IconButton>
            <div style={{ marginLeft: 8, lineHeight: 0.8 }}>
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                {units[currentUnit]?.title}
              </span>
              <br />
              <span style={{ fontSize: 14, color: "#464646" }}>
                {parseInt(
                  units[currentUnit] ? units[currentUnit]?.estimatedTime : ""
                )}{" "}
                mins
              </span>
            </div>
          </div>
        ) : null}

     
        {typPC == "Prepare" ? null : <LanguageSelection
          selectedLang={selectedLang}
          isFullScreen={isFullScreen}
          show={(isIR || isBPUT) && (slides?.[currentScene]?.multiLang) ? true : false}
          onLanguageChange={(language) => setSelectedLang(language)}
          langugeList={tagDetail?.showLanguages || Object.keys(slides?.[currentScene]?.url || {})}
        />}

        {/* </div> : null} */}
        {layout({
          h: isFullScreen ? ScreenHeight - 48 : slideHeight,
          w: isFullScreen ? ((ScreenHeight - 48) * 16) / 9 : slideWidth,
        })}
      </div>
    </div>
  );
};
const styles = (props) => ({
  colorPrimary: {
    backgroundColor: "#CCCC00",
  },
  barColorPrimary: {
    backgroundColor: "#c6922a",
  },
});
export default withStyles(styles)(LinearNavigator);
