import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
// import { getEngagement } from '../../containers/application/redux_actions'
// import { startEngagement, stopEngagement } from '../../containers/application/Socket';
import Interaction from "../../containers/application/Interaction";
import "./style.css";
import store from "../../Redux_Store";
import { addFormValues, pollAfterIdeaBoard } from "../../containers/application/redux_actions";

function etah(event) {
  // event type and event handler (etah)
  console.log("etah", event);
  switch (event.eventName) {
    case "START":
      console.log("Hello");
      break;
    case "STOP":
      // Interaction.stopEngagement()
      console.log("Bye");
      break;
    case "SUBMIT":
      console.log("Submit");
      store.dispatch({
        type: "STUDENT_RESPONSE",
        payload: {
          userId: event.senderId,
          data: event.eventData,
          engagementId: event.engagementId,
        },
      });
      break;

    // case "TEAM_BOARD_SUBMIT":
    //     console.log("TEAM_BOARD_SUBMIT")
    //     store.dispatch({ type: "TEAM_BOARD_STUDENT_RESPONSE", payload: { userId: event.senderId, data: event.eventData, engagementId: event.engagementId } })
    //     break;
    case "JOIN_SESSION":
      store.dispatch({ type: "STUDENT_JOINED", payload: event.eventData });
      console.log(event.eventData);
      break;
    default:
      console.log(event);
  }
}
export default ({
  engagementData,
  isFullScreen,
  data,
  scenarioId,
  scenario,
  engagementStatus,
  assignGroup,
  startText, endText,
  ideaBoard, restart,
  user_token
}) => {
  const responses = useSelector((state) => state.app.response[data.engagement]);
  const students = useSelector(
    (state) => state.app.uniqueStudentsName[data.engagement]
  );
  const userId = useSelector((state) => state.auth.user.id);
  const otp = useSelector((state) => state.app.otpData.otp);
  const studentJoinedList = useSelector((state) => state.app.studentJoinedList);
  const createdSession = useSelector((state) => state.app.createdSession);
  const status = useSelector(
    (state) => state.app.engagementStatus[data.engagement]
  );

  let responseCount = 0;
  if (responses) {
    Object.keys(responses).map((item) => {
      responseCount += responses[item].length;
    });
  }
  const sendEngaData = () => {

    // let scenario = { stu_responses, stu_eng, studentJoinedList }
    if (ideaBoard) {
      pollAfterIdeaBoard({ sessionId: createdSession?.id || createdSession?._id, engagementId: data.engagement, user_token, }, "PATCH")
    }
    else (
      addFormValues({ sessionId: createdSession?.id || createdSession?._id, user_token, fieldData: scenario || {} })
    )
  }
  useEffect(() => {
    Interaction.initalize(
      data.engagement,
      etah,
      "Coach",
      userId,
      "Student",
      otp
    );
  }, [data.engagement]);
  const changeButtonType = async () => {
    if (createdSession && otp) {
      store.dispatch({
        type: "START_AND_STOP_ENGAGEMENT",
        payload: { engId: data.engagement, status },
      });

      if (!engagementStatus || !engagementStatus[data.engagement]) {
        await Interaction.startEngagement();
        Interaction.sendEvent("START", data.engagement);
        if (assignGroup && engagementData[data.sessionType]) {
          let groupData = {};
          Object.keys(engagementData[data.sessionType]).map((key) => {
            if (
              engagementData[data.sessionType][key].item &&
              Object.keys(engagementData[data.sessionType][key].item).length > 0
            ) {
              // groupData[key] = engagementData[data.sessionType][key].item
              groupData[key] = Object.keys(
                engagementData[data.sessionType][key].item
              ).map(
                (_item) =>
                  engagementData[data.sessionType][key]["item"][_item].userId
              );
            }
          });

          Interaction.sendEvent("ASSIGN_GROUP", { ...groupData });
        }
      } else {
        sendEngaData()
        store.dispatch({ type: "STORE_SCENARIO_ID", payload: { data: scenarioId, engagementId: data.engagement } });
        Interaction.sendEvent("STOP", data.engagement);
        Interaction.stopEngagement();
      }
    }
  };
  // let noOfStudentsSize = '26px';
  // let studentRespondedSize = '9px'
  if (isFullScreen) {
    // noOfStudentsSize = '42px';
    // studentRespondedSize = '12px'
  }


  // console.log(totalStudent, students, responseCount, studentJoinedList)
  return (
    <>
      {!restart ?
        <>
          <div className="w-100 h-100 d-flex">
            {!engagementStatus || !engagementStatus[data.engagement] ? (
              <Button style={{
                padding: ideaBoard ? "8px" : '16px 25px',
                width: ideaBoard ? "100%" : null
              }}
                variant="contained"
                className={ideaBoard ? "ideaBoard_start_btn" : "live-session-stop-button h-100"}
                onClick={changeButtonType}
              >
                {startText || "Start Poll"}
              </Button>
            ) : (
              <Button style={{
                padding: ideaBoard ? "8px" : '16px 25px',
                width: ideaBoard ? "100%" : null
              }}
                variant="contained"
                className={ideaBoard ? "ideaBoard_stop_btn" : "live-session-stop-button h-100"}
                onClick={changeButtonType}
              >
                {endText || "Stop"}
              </Button>
            )}
          </div>
        </>
        : <>
          <div className="w-100 h-100 d-flex">
            {!engagementStatus || !engagementStatus[data.engagement] ? (
              <Button style={{

                width: ideaBoard ? "100%" : null,
                padding: !engagementStatus[data.engagement] && restart ? "0" : ideaBoard ? "8px" : '16px 25px',
                background: !engagementStatus[data.engagement] && restart && 'none',
                boxShadow: !engagementStatus[data.engagement] && restart && 'none',
                color: !engagementStatus[data.engagement] && restart && 'red'
              }}
                variant="contained"
                className={restart ? "" : ideaBoard ? "ideaBoard_start_btn" : "live-session-stop-button h-100"}
                onClick={changeButtonType}
              >
                {startText || "Start Poll"}
              </Button>
            ) : (
              <Button style={{
                padding: ideaBoard ? "8px" : '16px 25px',
                width: ideaBoard ? "100%" : null
              }}
                variant="contained"
                className={ideaBoard ? "ideaBoard_stop_btn" : "live-session-stop-button h-100"}
                onClick={changeButtonType}
              >
                {endText || "Stop"}
              </Button>
            )}
          </div>
        </>
      }
    </>
  );
};
