import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import NavLink from "./NavLink";
import { connect } from "react-redux";
import PeopleIcon from '@material-ui/icons/People';
// import { deregister, offline, register } from "./offline"
import { GetApp } from "@material-ui/icons";
import { makeOffline } from "../../application/redux_actions";
import './style.css'
import { t } from "i18next";
import { showBlog } from "../../../utilities/common";

const styles = {};
const logo = require('./assets/pedgog.svg');
const grp = require('./assets/conduct.svg');
const resourceSvg1 = require('./assets/resourceSvg1.svg');
const logoutIcon = require('./assets/logout_black_24dp.svg')
class LeftNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accesscode: "",
      animate: false,
      offline: false,
      language: props.language
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState((prevState) => {
      return { animate: !prevState.animate }
    });
  }

  routeChange() {
    let path = `/application`;
    this.props.history.push(path);
  }

  render() {
    return (
      <div className={"ap-left-nav h-100"}>
        <form className={"h-100"}>
          <nav className={"h-100"}>
            <ul className={"h-100"} >
              <div>
                <div style={{ padding: '1rem' }}>
                  <img src={logo} alt="logo" style={{ display: 'block', margin: 'auto' }} />
                </div>
                <NavLink to="/application">
                  <img src={grp} alt="grp" className="ap-nav-img" />
                  <label className='ap-nav-link-profile-text'>{t('PROGRAM')}</label>
                </NavLink>
                {showBlog ? <NavLink to="/application/blog">
                  <img src={resourceSvg1} alt="grp" className="ap-nav-img"/>
                  <label className='ap-nav-link-profile-text'>{t('RESOURCES')}</label>
                </NavLink> : null}
                {/* <NavLink to="/application/batch">
                  <PeopleIcon className="ap-nav-img" alt="batch" />
                  <label className='ap-nav-link-profile-text'>{t('BATCHES')}</label>
                </NavLink> */}
                {/* <NavLink to="/application/batchTracker">
                  <PeopleIcon className="ap-nav-img" alt="batch" />
                  <label className='ap-nav-link-profile-text'>Batch Upload Tracker</label>
                </NavLink> */}
              </div>
              <div className='last-div' style={{ justifyContent: 'flex-end' }}>                
                <NavLink to="/auth/logout">
                  <img src={logoutIcon} alt="ap-nav-img" className="ap-nav-img" />
                  <label>{t('LOGOUT')}</label>
                </NavLink>
              </div>
            </ul>
          </nav>
        </form>
      </div >
    );
  }
}

LeftNav.propTypes = {
  classes: PropTypes.object.isRequired,
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app
});

export default connect(mapStateToProps, null)(withStyles(styles)(LeftNav));