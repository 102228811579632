import React from "react";
import { Switch, Route } from "react-router-dom";

import Prepare from "../application/channelPrepare";
import Conduct from "../application/conduct";
import BlogIndex from "./blog";
import Media from "./blog/resource";
import App_ExploreDashboard from "../../components/App_ExploreDashboard";
import BatchDashboard from "./BatchDashboard";
import BatchTracker from "./BatchTracker";
// import OTP from "./OTP";

export default (props) => {
 let { app, auth } = props;
 return <Switch>
  {/* <Route exact path="/application/otp/:moduleId" component={OTP} /> */}
  <Route exact path="/application/prepare/:moduleId" component={Prepare} />
  <Route exact path="/application/conduct/:moduleId" component={Conduct} />
  <Route exact path="/application/blog/:postId/:resourceId" component={Media} />
  <Route app={app} exact path="/application/blog" component={BlogIndex} />
  <Route exact path="/application/batch" render={() => (
   <BatchDashboard app={app} auth={auth} />
  )} />
  <Route exact path="/application/batchTracker" render={() => (
   <BatchTracker />
  )} />
  <Route path="/application" render={() => (
   <App_ExploreDashboard app={app} auth={auth} />
  )} />
 </Switch>
}