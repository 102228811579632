import React from "react"
import { FormControl, InputLabel, Select } from "@material-ui/core"

export default ({ options, label, inputProps, value, onChange, placeholder }) => {
    return <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
        <Select
            native
            label={label}
            value={value}
            inputProps={inputProps}
            onChange={onChange}
        >
            <option value="" disabled> {placeholder} </option>
            {options.map((opt) => <option key={opt} value={opt}>{opt}</option>)}
        </Select>
    </FormControl>
}