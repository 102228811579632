import React, { useState } from "react";
import { useSelector } from "react-redux";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { IconButton } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import "./dipstick.css";
import ProgressBar from "../ProgressBar";
// import { easeLinear } from "d3";

Chart.plugins.register(ChartDataLabels);

export default ({
  width,
  height,
  engagementData,
  manageToolData,
  isFullScreen,
  sessionId,
  engagementStatus,
  typPC,
  typeOfEngagment,
  data,
  // responses,
  currentEngagement,
  // uniqueStudentsName,
}) => {

  let { uniqueStudentsName, responseCount, names, studentJoinedList, response, singleName } = useSelector((state) => state.app)
  let studentPresent = uniqueStudentsName

  let [show, setShow] = useState(false);
  let [show1, setShow1] = useState(false);

  let dipArray = [];
  let totalStudents = Object.values(studentJoinedList)?.length;

  let engConfig = currentEngagement?.questions?.[0]
  let typeofEng = engConfig?.type;
  let options = Object.values(engConfig?.answers || {}).map((option, i) => {
    return option?.option
  })

  let newDipLabel = {};

  if (responseCount) {
    Object.keys(responseCount)
      .map((key, i) => {
        let answers = [];
        let studentslabel = [];
        let Obj = {};
        let labelObj = {};
        let newObj = {};
        dipArray.push(responseCount[key]);
        data && data.Poll_engagements && data.Poll_engagements.map((pollKeys) => {
          if (key == pollKeys) {
            Object.values(responseCount[key]).map((ts) => {
              Object.values(ts).map((fs) => {
                if (typeofEng == "MultipleCorrect") {
                  answers.push(fs);
                } else {
                  fs.map((ds) => {
                    answers.push(ds);
                  });
                }
              });
            });
            if (singleName[key]) {
              names[key].map((el, i) => {
                if (answers[i]) {
                  let Obj1 = Object.assign({}, { username: el, ans: answers[i] });
                  studentslabel.push(Obj1);
                  Obj[answers[i]] = [];
                }
              });
              Object.keys(Obj) &&
                Object.keys(Obj).map((ts) => {
                  let qwer = [];
                  studentslabel.map((el) => {
                    if (ts === el.ans) {
                      qwer.push(el.username);
                      Obj[el.ans] = qwer;
                    }
                  });
                });
            }

            if (Object.keys(Obj)) {
              if (typeofEng == "MultipleCorrect") {
                studentPresent && studentPresent[key] && studentPresent[key].map((el) => {
                  newObj[el] = [];
                  let ans = [];
                  names[key].map((ts, k) => {
                    if (el == studentslabel[k].username) {
                      ans.push(studentslabel[k].ans);
                    }
                    newObj[el] = ans[ans.length - 1];
                  });
                });
              } else if (typeofEng == "SingleCorrect") {
                studentPresent && studentPresent[key] && studentPresent[key].map((el) => {
                  newObj[el] = [];
                  let ans = [];
                  Object.values(studentslabel).map((ts, k) => {
                    if (studentslabel[k].ans) {
                      if (el == studentslabel[k].username) {
                        ans.push(studentslabel[k].ans);
                      }
                      newObj[el] = ans[ans.length - 1];
                    }

                  });
                });
              }
              options.map((opt, i) => {
                let labArr = [];
                if (typeofEng == "MultipleCorrect") {
                  Object.values(newObj).map((key, k) => {

                    key.map((_ans, i) => {
                      if (opt == _ans) {
                        labArr.push(Object.keys(newObj)[k]);
                      }
                    });
                  });
                } else if (typeofEng == "SingleCorrect") {
                  Object.values(newObj).map((_ans, k) => {
                    if (opt == _ans) {
                      labArr.push(Object.keys(newObj)[k]);
                    }
                  });
                }
                labelObj[opt] = labArr;
              });
              newDipLabel = Object.assign(newDipLabel, { [pollKeys]: labelObj });
            }
          }
        });
      });
  }
  const scenario = {
    [data?.engagement]: {
      "responses": response,
      "uniqueStu": uniqueStudentsName,
      "totalStu": studentJoinedList,
      "newDipLabel": newDipLabel
    }
  }

  function Progress_bar(percentage, color) {
    const Parentdiv = {
      height: "100%",
      width: "100% ",
      backgroundColor: "#F5F5F5",
      // borderRadius: `${br}`,
    };

    const Childdiv = {
      height: "100%",
      width: `${percentage || 0}%`,
      backgroundColor: `${color}`,
      textAlign: "right",
    };

    return (
      <div style={Parentdiv}>
        <div style={Childdiv}></div>
      </div>
    );
  }

  let progessBarValue =
    (uniqueStudentsName?.[data.engagement]?.length / totalStudents) * 100;
  return (
    <div >
      {data?.showResultBtn && options && uniqueStudentsName && (
        <>
          {show && <div
            className="horizontal-poll"
            style={{
              left: " 25px",
              width: !isFullScreen ? "45%" : "45%",
              height: !isFullScreen ? "280px" : "max-content",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ fontSize: '1.5rem', fontWeight: 700 }}>Previous Poll</span>
            <IconButton
              style={{
                position: "absolute",
                top: "0px",
                right: "10px",
                marginTop: "2%",
                width: "12%",
                padding: "0px !important",
              }}
              onClick={() => {
                setShow(false);
              }}
            >
              <CloseSharpIcon fontSize="small" />
            </IconButton>

            {options.map((op, iOP) => {
              let progBar1 = [];
              let progBar2 = [];
              let barValue;
              if (op && Object.keys(newDipLabel).length > 0 && typeofEng) {
                Object.keys(newDipLabel[Object.keys(newDipLabel)[0]]).map(
                  (_opkey) => {
                    Object.keys(newDipLabel).map((_engKey, index) => {
                      if (index === 0) {
                        let num =
                          (newDipLabel[_engKey][_opkey].length /
                            studentPresent[_engKey].length) *
                          100;
                        // console.log("num", uniqueStudentsName[_engKey].length);

                        num % 1 == 0 ? barValue = num : barValue = num?.toFixed(2);
                        progBar1.push(barValue);
                      } else if (index === 1) {
                        let num =
                          (newDipLabel[_engKey][_opkey].length /
                            studentPresent[_engKey].length) *
                          100;

                        num % 1 == 0 ? barValue = num : barValue = num?.toFixed(2);
                        progBar2.push(barValue);
                      }
                    });
                  }
                );
              }

              return (
                <div
                  id={op}
                  style={{
                    // position: "absolute",
                    // top: "0px",
                    width: "100%",
                    displapy: "flex",
                    margin: "2% 0% 2% 0%",
                    flexDirection: "column",
                  }}
                >
                  <div
                    id={op}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      fontSize: "20px",
                      fontWeight: 700,
                      width: "100%",
                      fontSize: !isFullScreen ? "0.8rem" : "1.2rem",
                    }}
                  >
                    <div style={{ width: "80%" }}>{op}</div>
                    <div style={{ displapy: "flex", flexDirection: "column" }}>
                      <div
                        // id={op}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          data-for={op + iOP}
                          data-tip=""
                          style={{
                            width: "80%",
                            height: !isFullScreen ? "12px" : "15px",
                            marginTop: "1%",
                          }}
                        >
                          {Progress_bar(progBar1[iOP], "#DFB7FF ")}
                        </div>

                        <ReactTooltip id={op + iOP}>
                          <ul>
                            {Object.keys(newDipLabel)[0]
                              ? Object.keys(
                                newDipLabel[Object.keys(newDipLabel)[0]]
                              ).map((_key) => {
                                if (op == _key) {
                                  return newDipLabel[
                                    Object.keys(newDipLabel)[0]
                                  ][_key].length > 0 ? (
                                    newDipLabel[Object.keys(newDipLabel)[0]][
                                      _key
                                    ].map((_users, k) => {
                                      return <li key={k}>{_users}</li>;
                                    })
                                  ) : (
                                    <li>No users</li>
                                  );
                                }
                              })
                              : ""}
                          </ul>
                        </ReactTooltip>
                        <div>{`${progBar1[iOP] || 0}%`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>}
          {show1 && <div
            className="horizontal-poll"
            style={{
              right: '25px',
              // border: '1px solid #ADE1FF',
              width: !isFullScreen ? "45%" : "45%",
              // height: !isFullScreen ? "280px" : "max-content",
              height: !isFullScreen ? "280px" : "max-content",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
            }}
          >
            <span style={{ fontSize: '1.5rem', fontWeight: 700 }}>Current Poll</span>
            <IconButton
              style={{
                position: "absolute",
                top: "0px",
                right: "10px",
                marginTop: "2%",
                width: "12%",
                padding: "0px !important",
              }}
              onClick={() => {
                setShow1(false);
              }}
            >
              <CloseSharpIcon fontSize="small" />
            </IconButton>

            {options.map((op, iOP) => {
              let progBar1 = [];
              let progBar2 = [];
              let barValue;
              if (op && Object.keys(newDipLabel).length > 0 && typeofEng) {
                Object.keys(newDipLabel[Object.keys(newDipLabel)[0]]).map(
                  (_opkey) => {
                    Object.keys(newDipLabel).map((_engKey, index) => {
                      if (index === 0) {
                        let num =
                          (newDipLabel[_engKey][_opkey].length /
                            studentPresent[_engKey].length) *
                          100;
                        // console.log("num", uniqueStudentsName[_engKey].length);

                        num % 1 == 0 ? barValue = num : barValue = num?.toFixed(2);
                        progBar1.push(barValue);
                      } else if (index === 1) {
                        let num =
                          (newDipLabel[_engKey][_opkey].length /
                            studentPresent[_engKey].length) *
                          100;
                        num % 1 == 0 ? barValue = num : barValue = num?.toFixed(2);
                        progBar2.push(barValue);
                      }
                    });
                  }
                );
              }
              // console.log(progBar1, progBar2);

              return (
                <div
                  id={op}
                  style={{
                    // position: "absolute",
                    // top: "0px",
                    width: "100%",
                    displapy: "flex",
                    margin: "2% 0% 2% 0%",
                    flexDirection: "column",
                  }}
                >
                  <div
                    id={op}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      fontSize: "20px",
                      fontWeight: 700,
                      width: "100%",
                      fontSize: !isFullScreen ? "0.8rem" : "1.2rem",
                    }}
                  >
                    <div style={{ width: "80%" }}>{op}</div>
                    <div style={{ displapy: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          data-for={op}
                          data-tip=""
                          style={{
                            width: "80%",
                            height: !isFullScreen ? "12px" : "15px",
                            marginTop: "1%",
                          }}
                        >
                          {Progress_bar(progBar2[iOP], "#ADE1FF")}
                          {/* {Progress_bar(progBar2[iOP] || 0)} */}
                        </div>

                        <ReactTooltip
                          id={op}
                        // aria-haspopup="true" role="example"
                        >
                          <ul>
                            {Object.keys(newDipLabel)[1]
                              ? Object.keys(
                                newDipLabel[Object.keys(newDipLabel)[1]]
                              ).map((_key) => {
                                if (op == _key) {
                                  return newDipLabel[
                                    Object.keys(newDipLabel)[1]
                                  ][_key].length > 0 ? (
                                    newDipLabel[Object.keys(newDipLabel)[1]][
                                      _key
                                    ].map((_users, k) => {
                                      return <li key={k}>{_users}</li>;
                                    })
                                  ) : (
                                    <li>No users</li>
                                  );
                                }
                              })
                              : ""}
                          </ul>
                        </ReactTooltip>
                        <div>{`${progBar2[iOP] || 0}%`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>}
        </>
      )}
      <ProgressBar
        typPC={typPC}
        data={data}
        engagementData={engagementData}
        manageToolData={manageToolData}
        isFullScreen={isFullScreen}
        responses={response}
        currentEngagement={currentEngagement}
        uniqueStudentsName={uniqueStudentsName[data.engagement]}
        sessionId={sessionId}
        progessBarValue={progessBarValue}
        show={show}
        setShow={setShow}
        show1={show1}
        setShow1={setShow1}
        scenario={scenario}
        // answers={newDipLabel}
        totalStudents={totalStudents}
        engagementStatus={engagementStatus}
      />
    </div>
  );
};
