import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
// import { getEngagement } from '../../containers/application/redux_actions'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import "./styles.css";
import { v4 } from "uuid";
import Start_N_Stop from "../Start_N_Stop";
// import InputLabel from '@material-ui/core/InputLabel';
import Search from "@material-ui/icons/Search";
import Drawer_case from "./Drawer_case";
import ListView_drawer from "./dialog";
import Card from './card'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import ReactTooltip from "react-tooltip";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Drawer,
  Typography,
  Button,
  TextField,
  Select,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import store from "../../Redux_Store";
import NewComp from "./newComp";
import Compare from "./compare";
import CustomizedDialogs from "./dialog";
// import { mdiConsoleLine } from "@mdi/js";
// import { selectAll } from "d3";

export default ({
  width,
  height,
  currentEngagement,
  engagementData,
  manageToolData,
  isFullScreen,
  data1,
  engagementStatus,
  sessionId,
  clueCardResponses,
}) => {

  const responses = useSelector(
    (state) => state.app.response[data1.engagement]
  );
  const answers1 = useSelector((state) => state.app.responseCount1);
  const prevStateIdeaBoard = useSelector((state) => state.app.ideaBoard_map[data1.engagement]);
  // const [search, setSearch] = useState("");
  const [tag_filter, setTag_filter] = useState("");
  const [text, setText] = useState("");
  const [expanded, setExpanded] = useState("panel2");
  const [view, setView] = useState("map")
  const [editOn, setEditOn] = useState({})
  const [drop, setDrop] = useState()
  const [addResp, setAddResp] = useState(true)
  const [showSearch, setShowSearch] = useState(false)
  const [compare, setCompare] = useState(false)
  const [viewC, setViewC] = useState('map')
  const [open, setOpen] = React.useState(false);
  let teacherName = "teacher"
  const allTags = currentEngagement?.ideaBoard?.tags
  const [fakeDragApp, setFakeDragApp] = useState(
    {
      //columns
      First: {
        title: "Ideal0", //key
        item:
          data1?.fake_responses &&
          Object.keys(data1?.fake_responses).map((r) => ({
            id: v4(),
            text: r,
            // user: data1?.fake_responses[r].map((u) => u.user),
            tags: data1?.fake_responses[r].map((u) => u.tags),
            checked: true,
          })),
      },

      Second: {
        title: "Financial and Transactional Value",
        item: [],
      },

      Third: {
        title: "Utility Value",
        item: [],
      },

      Four: {
        title: "Experiential Value",
        item: [],
      },

      Five: {
        title: "Human and Social Value",
        item: [],
      },
    }
  );
  const [clueCards, setClueCards] = useState(
    {
      //columns
      First: {
        title: "Ideal0", //key
        item:
          data1?.clueCards &&
          Object.keys(data1?.clueCards).map((r) => ({
            id: v4(),
            text: r,
            // user: data1?.clueCards[r].map((u) => u.user),
            user: "ILLUMINE",
            tags: data1?.clueCards[r].map((u) => u.tags),
            checked: false,
          })),
      },

      Second: {
        title: "Financial and Transactional Value",
        item: [],
      },

      Third: {
        title: "Utility Value",
        item: [],
      },

      Four: {
        title: "Experiential Value",
        item: [],
      },

      Five: {
        title: "Human and Social Value",
        item: [],
      },
    }

  );
  const [dragApp, setDragApp] = useState(prevStateIdeaBoard ||
  {
    //columns
    First: {
      title: "Ideal0", //key
      item:
        responses &&
        Object.keys(responses).map((r) => ({
          id: v4(),
          text: r,
          user: responses[r].map((u) => u.user),
          tags: responses[r].map((u) => u.tags),
          checked: false,
        })),
    },

    Second: {
      title: "Financial and Transactional Value",
      item: [],
    },

    Third: {
      title: "Utility Value",
      item: [],
    },

    Four: {
      title: "Experiential Value",
      item: [],
    },

    Five: {
      title: "Human and Social Value",
      item: [],
    },
  }
  );

  useEffect(() => {
    let current = []
    prevStateIdeaBoard && Object.values(prevStateIdeaBoard).forEach(app => {
      app.item && Object.values(app.item).forEach(i => {
        current.push(i?.text)
      })
    })
    setDragApp({
      ...dragApp,
      First: {
        title: "Ideal0", //key
        item:
          responses &&
          Object.keys(responses).filter(r => !current.includes(r)).map(r => ({
            id: v4(),
            text: r,
            user: responses[r].map((u) => u.user),
            tags: responses[r].map((u) => u.tags),
            checked: false,
          })),
      },
    });
    // }
  }, [responses]);

  // useEffect(() => {
  //   store.dispatch({ type: "IDEABOARD_MAP", payload: { data: dragApp, engagementId: data1.engagement } });
  // }, [dragApp])

  let newArr;
  if (dragApp && dragApp['First'] && dragApp["First"]?.item) {
    let tagArr = []
    Object.values(dragApp['First'].item).map(i => {
      tagArr.push(i.tags[0])
    })
    newArr = [...new Set(tagArr)]
  }

  const addItem = (e) => {
    setDragApp((prev) => {
      let temp = prev.First.item || [];
      return {
        ...prev,
        First: {
          title: "Ideal0",
          item: [
            {
              id: v4(),
              text: text,
              user: [teacherName],
              tags: [],
              checked: false,
            },
            ...temp,
          ],
        },
      };
    });
    setText("");
  };

  const handleDropDown = (key, e, newValue) => {
    let index = dragApp[key].item.findIndex((i) => i.id === e.id);
    let title;
    Object.keys(dragApp).forEach(key => {
      if (dragApp[key].title == newValue[0])
        title = key
    })
    if (title.length > 1 && dragApp[key] && dragApp[key].item) {
      setDragApp((prev) => {
        prev = { ...prev }
        prev[key].item[index].tags = newValue
        let newObj = prev[key].item[index]
        prev[key].item.splice(index, 1)
        prev[title].item.push(newObj)
        setEditOn(oldState => ({ ...oldState, [e?.id]: false }))
        return prev
      })
    }
  }
  const handleSaveText = (key, e, newValue) => {
    // setEditOn(oldState => ({ ...oldState, [e?.id]: false }))
    let index = dragApp[key].item.findIndex((i) => i.id === e.id);
    if (dragApp[key] && dragApp[key].item && newValue.length > 0) {
      setDragApp((prev) => {
        prev = { ...prev }
        prev[key].item[index].text = newValue
        return prev
      })
    }
  };

  // Remove Ideas from list

  const handleRemove = (key, el) => {
    // let index = dragApp[key].item.findIndex((i) => i.id ===id)
    let items = dragApp[key].item.filter((i) => i.id !== el.id);
    setDragApp({
      ...dragApp, [key]: { ...dragApp[key], item: items }
    });
    // store.dispatch({ type: "IDEABOARD_MAP", payload: { data: prev, engagementId: data1.engagement } });
  };

  // handling drag & drop

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }
    const itemCopy = { ...dragApp[source.droppableId].item[source.index] };
    setDragApp((prev) => {
      prev = { ...prev };
      prev[source.droppableId].item.splice(source.index, 1);
      prev[destination.droppableId].item.splice(destination.index, 0, itemCopy);
      return prev;
    });

  };

  const getCardBg = (el) => {
    if (el == "Financial and Transactional Value") {
      return "#FFF2CC"
    }
    else if (el == "Utility Value") {
      return "#FBE5D6"
    }
    else if (el == "Experiential Value") {
      return "#E2F0D9"
    }
    else if (el == "Human and Social Value") {
      return "#ff000033"
    }
  }
  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "panel2");
  };

  const addNewRes = async (tag, _text) => {
    setDragApp((prev) => {
      prev = { ...prev }
      let obj = {
        id: v4(),
        text: _text,
        user: [teacherName],
        tags: tag,
        checked: true
      }
      prev['First'].item.push(obj)
      return prev
    })
    // console.log("dragApp", dragApp)
    // setText("");
  }

  const checkBoxHandle = (el) => {
    // let index = 0
    if (el.user == "ILLUMINE") {
      let allItems = clueCards["First"]["item"];
      let index = allItems.findIndex(x => x.id === el.id)
      allItems[index].checked = !allItems[index].checked;
      setClueCards((oldState) => ({
        ...oldState,
        First: { ...oldState.First, item: allItems },
      }));
    }
    else {
      let allItems = dragApp["First"]["item"];
      let index = allItems.findIndex(x => x.id === el.id);
      allItems[index].checked = !allItems[index].checked;
      setDragApp((oldState) => ({
        ...oldState,
        First: { ...oldState.First, item: allItems },
      }));
    }
  };

  useEffect(() => {
    let isTeacherResponse = dragApp?.["First"]?.["item"]?.filter(i => i.user[0] == teacherName)?.length > 0
    if (isTeacherResponse) {
      addToBoard()
    }
  }, [dragApp?.First])

  const addToBoard = () => {
    setEditOn(false)
    setDragApp((prev) => {
      prev = { ...prev };
      let allItems = dragApp["First"]["item"];
      prev && Object.keys(prev).map((key, p) => {
        allItems && Object.keys(allItems).map((item, i) => {
          if (
            allItems[item]?.checked &&
            prev[key].title == allItems[item].tags?.[0]
          ) {
            prev[key].item.splice(1, 0, allItems[item]);
            allItems.splice(item, 1);
          }
        });
      });

      store.dispatch({ type: "IDEABOARD_MAP", payload: { data: prev, engagementId: data1.engagement } });
      return prev;
    });
  };
  const Accordion = withStyles({
    root: {
      border: "1px solid rgba(0, 0, 0, .125)",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: "rgba(0, 0, 0, .03)",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: -1,
      minHeight: 56,
      "&$expanded": {
        minHeight: 56,
      },
    },
    content: {
      "&$expanded": {
        margin: "12px 0",
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  const getBgColor = (index) => {

    if (index == 1) {
      return "#dd6161";
    }
    else if (index == 2) {
      return "#eabb17";
    }
    else if (index == 3) return "#748893"
    else return "#ff0000ab"

  }
  const getAlignment = (pos, index) => {

    if (index == 1) {
      if (pos == "top") return isFullScreen ? "1vh" : '2vh'
      else if (pos == "left") return "0vw"
    }
    if (index == 2) {
      if (pos == "top") return isFullScreen ? "1vh" : '2vh'
      else if (pos == "left") return isFullScreen ? "31vw" : '23vw'
    }
    if (index == 3) {
      if (pos == "top") return "40vh"
      else if (pos == "left") return "0vw"
    }
    if (index == 4) {
      if (pos == "top") return "40vh"
      else if (pos == "left") return isFullScreen ? "31vw" : '23vw'
    }
  }

  // my machine
  // const getAlignment = (pos, index) => {

  //   if (index == 1) {
  //     if (pos == "top") return "2vh"
  //     else if (pos == "left") return "0vw"
  //   }
  //   if (index == 2) {
  //     if (pos == "top") return "2vh"
  //     else if (pos == "left") return isFullScreen ? "33vw" : '21vw'
  //   }
  //   if (index == 3) {
  //     if (pos == "top") return "42vh"
  //     else if (pos == "left") return "0vw"
  //   }
  //   if (index == 4) {
  //     if (pos == "top") return "42vh"
  //     else if (pos == "left") return isFullScreen ? "33vw" : '21vw'
  //   }
  // }

  const selectAll = (which) => {
    // if (which == "dragApp") {
    setDragApp((prev) => {
      prev = { ...prev }
      Object.values(prev["First"].item).map(i => i.checked = !i.checked)
      return prev
    })
    // }
    // else {
    //   setClueCards((prev) => {
    //     prev = { ...prev }
    //     Object.values(prev["First"].item).map(i => i.checked = !i.checked)
    //     return prev
    //   })
    // }
  }
  const getFakeMap = () => {
    setCompare(true)
    setFakeDragApp((prev) => {
      prev = { ...prev }
      let allItems = fakeDragApp["First"]["item"];
      prev && Object.keys(prev).map((key, p) => {
        allItems && Object.keys(allItems).map((item, i) => {
          if (
            allItems[item]?.checked &&
            prev[key].title == allItems[item].tags?.[0]
          ) {
            prev[key].item.splice(1, 0, allItems[item]);
            allItems.splice(item, 1);
          }
        });
      });
      return prev
    })
  }
  const getClueCards = () => {
    return (
      <div className="scrollDiv" style={{ maxHeight: '42vh', height: 'max-content', overflow: "hidden auto", padding: '0vh 1vw 1vh 1vw' }}>
        {_.map(clueCards, (data, key) => {
          return (
            <>
              {key == "First" && allTags && allTags.map(tag => {
                return (
                  <div key={tag} id={tag + key} >
                    <span style={{ fontSize: '0.8rem' }}>{tag}</span>
                    <div style={{ marginTop: '-2%' }}>
                      {data &&
                        data.item &&
                        data.item
                          .filter((el) => {
                            if (el.tags[0] == tag) return el
                          })
                          .map((el) => {
                            return (
                              <Card
                                clueCards={clueCards}
                                setClueCards={setClueCards}
                                clues={true}
                                addToBoard={addToBoard}
                                allTags={allTags}
                                view={view}
                                key1={key}
                                handleSaveText={handleSaveText}
                                el={el}
                                // search={search}
                                tag_filter={tag_filter}
                                text={text}
                                setText={setText}
                                editOn={editOn}
                                setEditOn={setEditOn}
                                handleDropDown={handleDropDown}
                                handleRemove={handleRemove}
                                checkBoxHandle={checkBoxHandle}
                                getCardBg={getCardBg}
                              // bgColor={color}
                              />
                            );
                          })
                      }
                    </div>
                  </div>
                )
              })
              }
            </>
          )
        })}
      </div>
    )
  }


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "#F2F2F2"
      }}
    >
      {compare ?
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button onClick={() => setOpen(true)} style={{ marginLeft: '30vw', backgroundColor: '#FFBC36' }}>Finalize your map</Button>
          <div>
            <Button style={{ backgroundColor: viewC == 'map' ? '#F2F2F2' : 'white' }} onClick={() => setViewC('map')}>Map View</Button>
            <Button style={{ backgroundColor: viewC == 'list' ? '#F2F2F2' : 'white' }} onClick={() => setViewC('list')}>list View</Button>
          </div>
        </div>
        :
        <div style={{ margin: '1% 3%', fontWeight: 'bold' }}>
          {currentEngagement?.ideaBoard?.title}
        </div>}
      <div
        style={{
          display: "flex",
          marginTop: "1%",
          width: "100%",
          // height: "80vh",
          position: "relative",
        }}
      >
        {/* <div
          style={{
            width: "7%",
            height: "100%",
            background: "white",
            border: "1px solid grey",
            position: "absolute",
            top: "0px",
            left: "0px",
            padding: "1% 2%",
            overflowY: "auto",
            overflowX: "hidden",
            height: "100%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Drawer_case
              ModalProps={{
                container: document.getElementById("frame-id"),
                keepMounted: true,
              }}
            />
          </div>
        </div> */}
        <div
          style={{
            // border: "1px solid green",
            width: isFullScreen ? "98%" : '98%',
            position: "absolute",
            top: "0px",
            left: "0px",
            margin: "0% 1%",
            height: "100%",
          }}
        >
          <div style={{ position: "relative" }}>
            {!compare && <div style={{
              position: 'absolute', top: view == "map" ? '-2vh' : '-3vh',
              // right: view == "map" ? '29vw' : '1vw',
              right: '1vw',
              width: '70%', display: 'flex', justifyContent: 'flex-end'
            }}>
              <div>
                {/* <Button onClick={handleView}>{view == 'map' ? "List View" : "Map View"}</Button> */}
                <Button style={{ fontSize: '0.8rem', fontWeight: 'bold', textTransform: "none", backgroundColor: view == 'map' ? 'white' : '#F2F2F2' }} onClick={() => setView('map')}>Map View</Button>
                <Button style={{ fontSize: '0.8rem', fontWeight: 'bold', textTransform: "none", backgroundColor: view == 'list' ? 'white' : '#F2F2F2' }} onClick={() => setView('list')}>list View</Button>
              </div>
            </div>}
            {!compare ? view == "map" ? <DragDropContext
            // onDragEnd={handleDragEnd}
            >
              {_.map(dragApp, (data, key) => {
                return (
                  <Droppable droppableId={key} key={key}>
                    {(provided) => {
                      return (
                        <div
                          style={
                            Object.keys(dragApp).indexOf(key) == 0
                              ? {
                                display: "flex",
                                flexDirection: "column",
                                width: "30%",
                                position: "absolute",
                                right: "0px",
                                top: "3vh",
                                // height: '87vh',
                                padding: '1% 1%',
                              }
                              : {
                                height: "36vh",
                                padding: '1%',
                                position: "absolute",
                                width: "33%",
                                top: getAlignment("top", Object.keys(dragApp).indexOf(key)),
                                left: getAlignment("left", Object.keys(dragApp).indexOf(key)),
                                // border: `1px solid ${getBgColor(Object.keys(dragApp).indexOf(key))}`,
                                border: "1px solid #c9c9c9",
                                background: "white",
                                // overflowY: 'overlay',
                                borderRadius: '4px',
                                margin: '2% 0%'
                              }
                          }
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {Object.keys(dragApp).indexOf(key) == 0 ? (
                            <>
                              <Accordion
                                style={{ background: 'white', margin: '2% 0% 0% 0%', padding: 0 }}
                                square
                                expanded={expanded === "panel1"}
                                onChange={handleChange1("panel1")}
                              >
                                <AccordionSummary
                                  style={{ background: 'white' }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                >
                                  <Typography style={{ fontSize: '0.9rem', fontWeight: 'bold', width: '100%' }}>
                                    Clues
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
                                  <div style={{ fontSize: '0.8rem', padding: '1vh 1vw 0vh 1vw' }}>{currentEngagement?.ideaBoard?.clues}</div>
                                  {getClueCards()}
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                style={{ background: 'white', margin: '2% 0% 0% 0%', padding: 0 }}
                                square
                                expanded={expanded === "panel2"}
                                onChange={handleChange1("panel2")}
                              >
                                <AccordionSummary
                                  // expandIcon={<ExpandMoreIcon />}
                                  style={{ background: 'white', border: 0, }}
                                  aria-controls="panel2d-content"
                                  id="panel2d-header"
                                >
                                  <Typography style={{ fontSize: '0.9rem', fontWeight: 'bold', width: '100%' }}>
                                    {!addResp ?
                                      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                                        <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={() => setAddResp(oldstate => !oldstate)} />
                                        <div style={{ marginTop: '1% ', marginLeft: '3%' }}>
                                          Add new response
                                        </div>
                                      </div> :
                                      showSearch ?
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                                          <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={() => setShowSearch(false)} />
                                          <div style={{ marginTop: '-1% ', marginLeft: '3%' }}>
                                            search
                                          </div>
                                        </div> :
                                        engagementStatus[data1.engagement] ? `Participant Responses (${responses && Object.values(responses)?.length || 0})` : 'IdeaBoard is Offline'}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0 }}>
                                  {addResp ? <>
                                    <div className="response-div" style={{ marginTop: engagementStatus[data1.engagement] ? '-5%' : '0%' }}>
                                      {/* <h3 style={{ padding: '0% 2%' }}>{engagementStatus[data1.engagement] ? `Participant Responses (${responses && Object.values(responses)?.length || 0})` : 'IdeaBoard is Offline'}</h3> */}
                                      <div style={{ padding: '0% 4%' }}>
                                        {engagementStatus[data1.engagement] ?
                                          !showSearch ?
                                            <div className="buttons" style={{ marginTop: "4%" }}>
                                              <Button
                                                disabled={dragApp?.["First"]?.item?.length > 0 ? false : true}
                                                onClick={addToBoard}
                                                style={{ fontSize: '0.7rem', fontWeight: 'bold', padding: '0rem 1rem', textTransform: "none", backgroundColor: "#FFBC36" }}>Add To Board
                                              </Button>
                                              <Button style={{ border: '1px solid #BA1F00', color: '#BA1F00', fontSize: '0.7rem' }} onClick={() => setAddResp(oldstate => !oldstate)}> + New</Button>
                                              <div id={"op"} style={{ marginTop: '-1%' }}>
                                                <a data-tip data-for={"op"}>
                                                  <Checkbox
                                                    icon={<CircleUnchecked />}
                                                    checkedIcon={<CircleCheckedFilled />}
                                                    onChange={() => selectAll()}
                                                  />
                                                </a>
                                                <ReactTooltip id={"op"} aria-haspopup="true" role="example">
                                                  <span>Select All</span>
                                                </ReactTooltip>
                                              </div>
                                              <div id={"op1"} style={{ marginTop: '2%' }}>
                                                <a data-tip data-for={"op1"}>
                                                  <Search onClick={() => setShowSearch(true)} style={{ cursor: 'pointer', marginTop: '4%', marginRight: '-4%' }} />
                                                </a>
                                                <ReactTooltip id={"op1"} aria-haspopup="true" role="example">
                                                  <span>search</span>
                                                </ReactTooltip>
                                              </div>
                                            </div> :
                                            <NewComp
                                              // setSearch={setSearch} search={search} 
                                              mode={true} setShowSearch={setShowSearch} showSearch={showSearch} addNewRes={addNewRes} handleDropDown={handleDropDown} addItem={addItem} addResp={addResp} setAddResp={setAddResp}
                                              addToBoard={addToBoard} allTags={allTags} data={data} view={view} key1={key} handleSaveText={handleSaveText} tag_filter={tag_filter} editOn={editOn} setEditOn={setEditOn} handleRemove={handleRemove} checkBoxHandle={checkBoxHandle} getCardBg={getCardBg}
                                            // text={text} setText={setText}
                                            />
                                          : <div>
                                            {responses && Object.values(responses)?.length || 0}<br />
                                            responses from participants
                                          </div>
                                        }
                                      </div>
                                      <div
                                        className="scrollDiv"
                                        style={{
                                          padding: '0% 4%', marginTop: '2%', overflow: "hidden auto", height: 'max-content',
                                          maxHeight: expanded == "panel1" ? "20vh" : '42vh',
                                        }}>
                                        {engagementStatus[data1.engagement] && !showSearch && newArr && newArr.map(tag => {
                                          return (
                                            <div>
                                              <span style={{ fontSize: '0.8rem' }}>{tag}</span>
                                              <div style={{ marginTop: '-2%' }}>
                                                {data &&
                                                  data.item &&
                                                  data.item
                                                    .filter((el) => {
                                                      if (el.tags[0] == tag) {
                                                        return el
                                                      }
                                                    })
                                                    .map((el) => {
                                                      return (
                                                        <Draggable
                                                          isDragDisabled={true}
                                                          key={el.id}
                                                          // index={index}
                                                          draggableId={el.id}>
                                                          {(provided) => {
                                                            return (
                                                              <Card
                                                                engagementStatus={engagementStatus[data1.engagement]}
                                                                allTags={allTags}
                                                                view={view}
                                                                key1={key}
                                                                // index={index}
                                                                handleSaveText={handleSaveText}
                                                                el={el}
                                                                // search={search}
                                                                // setSearch={setSearch}
                                                                tag_filter={tag_filter}
                                                                text={text}
                                                                setText={setText}
                                                                editOn={editOn}
                                                                setEditOn={setEditOn}
                                                                handleDropDown={handleDropDown}
                                                                handleRemove={handleRemove}
                                                                checkBoxHandle={checkBoxHandle}
                                                                getCardBg={getCardBg}
                                                              // bgColor={color}
                                                              />
                                                            );
                                                          }}
                                                        </Draggable>
                                                      );
                                                    })
                                                }
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                      <div style={{ width: '100%', padding: '3% 3%' }}>
                                        <Start_N_Stop
                                          ideaBoard={true}
                                          width={width}
                                          height={height}
                                          engagementData={engagementData}
                                          manageToolData={manageToolData}
                                          data={data1}
                                          isFullScreen={isFullScreen}
                                          engagementStatus={engagementStatus}
                                          sessionId={sessionId}
                                          assignGroup={true}
                                          startText={"Accept Responses"}
                                          endText={"Stop Responses"}
                                        />
                                        {responses && Object.values(responses)?.length > 0 && !engagementStatus[data1.engagement] && <Button style={{ width: '100%', backgroundColor: 'whitesmoke', color: '#ba1f00', marginTop: '4%', borderRadius: '10px' }} onClick={getFakeMap}>Compare</Button>}
                                      </div>
                                    </div>
                                  </> :
                                    <NewComp addNewRes={addNewRes} handleDropDown={handleDropDown} addItem={addItem} addResp={addResp} setAddResp={setAddResp} data={data} key={key} addToBoard={addToBoard} allTags={allTags}
                                    // text={text} setText={setText} 
                                    />
                                  }
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ) : (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div style={{ lineHeight: '1.3rem', fontSize: '15px' }}>
                                {/* {heading[data.title]} */}
                                {data.title}
                              </div>
                              <div className="scrollDiv" style={{ height: isFullScreen ? '200px' : '140px', overflowY: "auto", overflowX: "hidden" }}>
                                {data &&
                                  data.item &&
                                  data.item
                                    .map((el, index) => {
                                      return (
                                        <Draggable
                                          // isDragDisabled={true}
                                          key={el.id}
                                          index={index}
                                          draggableId={el.id}
                                        >
                                          {(provided) => {
                                            let deck = true
                                            return (
                                              <Card
                                                engagementStatus={engagementStatus[data1.engagement]}
                                                allTags={allTags}
                                                view={view}
                                                deck={deck}
                                                key1={key}
                                                // index={index}
                                                handleSaveText={handleSaveText}
                                                el={el}
                                                // search={search}
                                                tag_filter={tag_filter}
                                                text={text}
                                                setText={setText}
                                                editOn={editOn}
                                                setEditOn={setEditOn}
                                                handleDropDown={handleDropDown}
                                                handleRemove={handleRemove}
                                                checkBoxHandle={checkBoxHandle}
                                                getCardBg={getCardBg}
                                              // bgColor={color}
                                              />

                                            );
                                          }}
                                        </Draggable>
                                      );
                                    })}

                              </div>
                            </div>
                          )}

                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                )
              })}
            </DragDropContext>
              :
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', position: 'absolute', top: '4vh', background: 'white', borderRadius: '4px', border: '1px solid #c9c9c9' }}>
                {_.map(dragApp, (data, key) => {
                  if (dragApp[key].title != "Ideal0") {
                    return (
                      <>
                        <div style={{
                          overflowY: 'auto', margin: '1%', width: '33%', display: 'flex', alignItems: 'center', flexDirection: 'column',
                          //  height: '77vh', 
                        }}>
                          <div style={{ fontSize: '0.8rem', textAlign: 'left', width: '100%' }}>
                            {data?.title}
                          </div>
                          <div className="scrollDiv" style={{
                            marginTop: "-2%",
                            overflowY: 'auto', width: '100%'
                          }}>
                            {Object.values(data?.item).map((el, index) => {
                              let deck = true
                              return (
                                <Card
                                  engagementStatus={engagementStatus[data1.engagement]}
                                  deck={deck}
                                  allTags={allTags}
                                  view={view}
                                  key1={key}
                                  // index={index}
                                  handleSaveText={handleSaveText}
                                  el={el}
                                  // search={search}
                                  tag_filter={tag_filter}
                                  text={text}
                                  setText={setText}
                                  editOn={editOn}
                                  setEditOn={setEditOn}
                                  handleDropDown={handleDropDown}
                                  handleRemove={handleRemove}
                                  checkBoxHandle={checkBoxHandle}
                                  getCardBg={getCardBg}
                                />
                              )
                            })}
                          </div>
                        </div>
                        <hr />
                      </>
                    )
                  }
                })}
              </div>
              :
              <Compare
                viewC={viewC}
                setViewC={setViewC}
                compare={compare}
                setCompare={setCompare}
                newArr={newArr}
                dragApp={dragApp}
                fakeDragApp={fakeDragApp}
                setFakeDragApp={setFakeDragApp}
                allTags={allTags}
                view={view}
                handleSaveText={handleSaveText}
                // search={search}
                tag_filter={tag_filter}
                text={text}
                setText={setText}
                editOn={editOn}
                setEditOn={setEditOn}
                handleDropDown={handleDropDown}
                handleRemove={handleRemove}
                checkBoxHandle={checkBoxHandle}
                getCardBg={getCardBg}
              />
            }
          </div>
          {/* <CustomizedDialogs parentId='frame-id'
            open={open}
            setOpen={setOpen}
          /> */}
        </div>
      </div>
    </div >
  );
};



